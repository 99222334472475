export const dateFormat = "YYYY-MM-DD";

export const dateTimeFormat = "YYYY-MM-DD HH:mm";

export const emailTemplateTypes = [
  "RESELLER_INVITATION",
  "RESELLER_PASSWORD_RESET",
  "ORDER_PLACED",
  "ORDER_PRODUCTS",
  "COMPANY_SIGNUP_APPLICATION_SUBMITTED",
  "COMPANY_SIGNUP_APPLICATION_ACTIVE",
  "COMPANY_SIGNUP_APPLICATION_REJECTED",
  "ORDER_CANCELLED",
  "ORDER_PRODUCT_DELIVERED",
  "ORDER_DIGITAL_PRODUCT_DELIVERED",
  "ORDER_PRODUCT_SHIPPED",
  "COMPANY_CREDIT_APPLICATION_SUBMITTED",
  "COMPANY_CREDIT_APPLICATION_ACTIVE",
  "COMPANY_CREDIT_APPLICATION_REJECTED",
  "COMPANY_CREDIT_APPLICATION_SUSPENDED",
];

export const paymentMethods = ["ALL", "CARD", "BANK", "CREDIT"];

export const paymentStatuses = ["ALL", "PENDING", "PAID", "REFUNDED"];

export const tables = {
  ORDERS: "Orders",
  ORDER_PRODUCTS: "Order_Products",
  COUNTRIES: "Countries",
  INVENTORY: "Inventory",
  CATEGORIES: "Categories",
  COMPANIES: "Companies",
  DIGITAL_KEYS: "Digital_Keys",
  EMAIL_TEMPLATES: "Email_Templates",
};

export const unecessaryWoocommerceProductFields = [
  'date_created',
  'date_created_gmt',
  'date_modified',
  'date_modified_gmt',
  'date_on_sale_from',
  'date_on_sale_from_gmt',
  'date_on_sale_to',
  'date_on_sale_to_gmt',
  'purchasable',
  'downloadable',
  'downloads',
  'download_limit',
  'download_expiry',
  'external_url',
  'button_text',
  'backordered',
  'catalog_visibility',
  'backorders',
  'backorders_allowed',
  'default_attributes',
  'has_options',
  'low_stock_amount',
  'manage_stock',
  'permalink',
  'price_html',
  'related_ids',
  '_links',
  'tags',
  'dimensions',
  'weight',
  'sold_individually',
  'upsell_ids',
  'cross_sell_ids',
  'grouped_products',
  'menu_order',
  'post_password',
  'purchase_note',
]