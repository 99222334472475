/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { styled } from "@mui/material/styles";

import PropTypes from "prop-types";

import axios from "axios";

import { Loading } from "notiflix/build/notiflix-loading-aio";

import Box from "@mui/material/Box";

import CircularProgress from "@mui/material/CircularProgress";

import MDBadge from "components/MDBadge";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import Edit from "@mui/icons-material/Edit";

import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import Typography from "@mui/material/Typography";

import CustomTabs from "components/CustomTabs";

import { globalCountry } from "store/entities/countries";

import SimpleProduct from "./SimpleProduct";
import MDButton from "components/MDButton";
import { AddBox } from "@mui/icons-material";
import SimpleProductAdd from "../AddInventory/AddVariationDialog";
import AddVariationDialog from "./AddVariationDialog";

import noPreview from "assets/images/no-preview.png";

import {
  useLazyGetAllResellerProductsByCountryIdQuery,
  useGetWoocommerceSiteProductsMutation,
} from "services/products";

import { resetStockId } from "store/entities/stockNotifications";

import { stripHTMLTags } from "utils/helperFunctions";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

import MDBox from "components/MDBox";

const EditInventoryDialog = ({ parentProductData, distinctAttributes, elementId }) => {
  const dispatch = useDispatch();

  const [openEditDialog, setOpenEditDialog] = useState(false);

  const [variationProductData, setVariationProductData] = useState([]);

  const [refetchedVariationProductData, setRefetchedVariationProductData] = useState([]);

  const [imageLoading, setImageLoading] = useState(true);

  const [productShortDescription, setProductShortDescription] = useState(
    parentProductData.short_description
  );
  const [productDescription, setProductDescription] = useState(parentProductData.description);

  const selectedCountry = useSelector(globalCountry);

  const [productData, setProductData] = useState({});

  const [attributeFields, setAttributeFields] = useState([]);

  const [isParentFeaturedProduct, setIsParentFeaturedProduct] = useState(
    parentProductData.is_featured_product
  );

  useEffect(() => {
    setProductData(parentProductData);
  }, [parentProductData]);

  const [
    refetchAllProducts,
    { data: productList = [], isLoading: isLoadingProductList, isSuccess: isSuccessProductList },
  ] = useLazyGetAllResellerProductsByCountryIdQuery();

  const handleClickOpen = () => {
    setOpenEditDialog(true);

    localStorage.setItem(
      "shortDescriptionState",
      JSON.stringify(parentProductData.short_description)
    );
    localStorage.setItem("descriptionState", JSON.stringify(parentProductData.description));

    let parentCategories = [];
    if (parentProductData.proucts_category && parentProductData.proucts_category.length > 0) {
      for (let category of parentProductData.proucts_category) {
        parentCategories.push(category.category_id);
      }
    }

    // parent name
    localStorage.setItem("localParentName", JSON.stringify(parentProductData.name));

    // parent categories
    if (parentCategories.length > 0) {
      localStorage.setItem("localParentCategories", JSON.stringify(parentCategories));
    }
  };

  const handleClose = () => {
    refetchAllProducts(selectedCountry.id);
    setOpenEditDialog(false);
    removeCachedData();
    dispatch(resetStockId());
  };

  const removeCachedData = () => {
    const cacheVariationPrefix = "cacheVariation";
    const shortDescriptionState = "shortDescriptionState";
    const descriptionState = "descriptionState";

    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith(cacheVariationPrefix)) {
        localStorage.removeItem(key);
      }
    });

    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith(shortDescriptionState)) {
        localStorage.removeItem(key);
      }
    });

    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith(descriptionState)) {
        localStorage.removeItem(key);
      }
    });

    localStorage.removeItem("localParentName");
    localStorage.removeItem("localParentCategories");
  };

  useEffect(() => {
    if (productData.type == "variable" && productData.productVariations.length > 0) {
      setVariationProductData(productData.productVariations);

      let productVariationAttribues = [];
      for (let productVariation of productData.productVariations) {
        if (
          productVariation.variation_atribute_option &&
          productVariation.variation_atribute_option.length > 0
        ) {
          for (let attribute of productVariation.variation_atribute_option) {
            if (attribute.products_attribute_options) {
              if (
                attribute.products_attribute_options.products_attributes &&
                attribute.products_attribute_options.products_attributes.attribute
              )
                productVariationAttribues = [
                  ...productVariationAttribues,
                  attribute.products_attribute_options.products_attributes.attribute,
                ];
            }
          }
        }
      }

      setAttributeFields(
        productVariationAttribues.filter((item, index) => {
          return productVariationAttribues.indexOf(item) === index;
        })
      );

      setProductDescription(parentProductData.description);
      setProductShortDescription(parentProductData.short_description);
    }
  }, [productData]);

  const variationTabs = variationProductData.map((item, index) => ({
    tabName:
      item.woocommerce_id == 0 ? (
        <>
          {`Variation ${index + 1}`}
          <MDBadge badgeContent="Custom" color="info" variant="gradient" size="sm" />
        </>
      ) : (
        `Variation ${index + 1}`
      ),
    tabContent: (
      <SimpleProduct
        parentData={productData}
        setProductData={setProductData}
        isParentFeaturedProduct={isParentFeaturedProduct}
        setIsParentFeaturedProduct={setIsParentFeaturedProduct}
        initialData={item}
        variationLength={productData.productVariations.length}
        dialogClose={handleClose}
        distinctAttributes={distinctAttributes}
        productShortDescription={productShortDescription}
        setProductShortDescription={setProductShortDescription}
        productDescription={productDescription}
        setProductDescription={setProductDescription}
      />
    ),
  }));

  return (
    <>
      <Tooltip title="Edit Product" placement="top">
        <IconButton
          aria-label="view"
          size="small"
          sx={{ ml: 1 }}
          onClick={handleClickOpen}
          id={elementId}
        >
          <Edit color="warning" fontSize="inherit" />
        </IconButton>
      </Tooltip>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        maxWidth="xl"
        sx={{
          "& .MuiDialog-paper": {
            width: "1000px",
          },
        }}
        open={openEditDialog}
      >
        <DialogTitle sx={{ m: 0, p: 2, color: "#3f3f3f" }} id="customized-dialog-title">
          Edit Inventory
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {productData.type != "simple" ? (
            <MDBox sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 400,
                  mb: 2,
                  mt: 1,
                  color: "#3f3f3f",
                }}
              >
                <strong>{productData.name} - Variations</strong>
              </Typography>
              <AddVariationDialog
                parentAttributes={attributeFields}
                parentProductData={parentProductData}
                distinctAttributes={distinctAttributes}
                closeVariationsDialog={handleClose}
              />
            </MDBox>
          ) : (
            ""
          )}
          <Box>
            {productData.type == "simple" ? (
              <SimpleProduct
                initialData={productData}
                variationLength={1}
                dialogClose={handleClose}
                distinctAttributes={distinctAttributes}
                productShortDescription={productShortDescription}
                setProductShortDescription={setProductShortDescription}
                productDescription={productDescription}
                setProductDescription={setProductDescription}
              />
            ) : (
              variationProductData &&
              variationProductData.length > 0 && (
                <CustomTabs headerColor="primary" tabs={variationTabs} />
              )
            )}
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

EditInventoryDialog.propTypes = {
  // children: PropTypes.node.isRequired, // You can also use PropTypes.element if you want to enforce a single element.
  // productData: PropTypes.object.isRequired,
};

export default EditInventoryDialog;
