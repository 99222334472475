/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import MenuItem from "@mui/material/MenuItem";

import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import { Confirm } from "notiflix/build/notiflix-confirm-aio";

import moment from "moment";

import { CustomSelect } from "components/CustomSelect";

//components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import DateTime from "components/DateTime";

import OrderDetails from "views/Orders/OrderDetails";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import RestartAltIcon from "@mui/icons-material/RestartAlt";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CancelIcon from "@mui/icons-material/Cancel";

import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// queries
import {
  useGetAllOrdersByCountryQuery,
  useLazyGetAllOrdersByCountryQuery,
  useChangeOrderPaymentStatusMutation,
  useRefundOrderMutation,
  useCancelOrderMutation,
} from "services/orders";

import { globalCountry } from "store/entities/countries";

import MDBadge from "components/MDBadge";
import Chip from "@mui/material/Chip";

import { dateFormat, paymentMethods, paymentStatuses, tables } from "utils/constants";

import Alert from "components/CustomAlert";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import { useGetAllBDMWithInactiveQuery } from "services/bdm";

import { notificationForwardSection, resetForwardSection } from "store/entities/notifications";

function Orders() {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [modifiedBDMArray, setModifiedBDMArray] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("ALL");
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState("ALL");
  const [selectedBDM, setSelectedBDM] = useState(0);

  const [totalSales, setTotalSales] = useState(0);
  const [orderTotal, setOrderTotal] = useState(0);
  const [refundedTotal, setRefundedTotal] = useState(0);

  const dispatch = useDispatch();

  const navigate = useHistory();

  const selectedCountry = useSelector(globalCountry);

  const notificationDetails = useSelector(notificationForwardSection);

  const [triggerOrders, { data: ordersList = [], isLoading, isSuccess }] =
    useLazyGetAllOrdersByCountryQuery();

  const {
    data: BDMList,
    isLoading: isBDMsLoading,
    isSuccess: isBDMsSuccess,
  } = useGetAllBDMWithInactiveQuery();

  const [changePaymentStatus, { isLoading: isOrderPaymentStatusesLoading }] =
    useChangeOrderPaymentStatusMutation();

  const [refundOrder, { isLoading: isRefundLoading }] = useRefundOrderMutation();

  const [cancelOrderMutation] = useCancelOrderMutation();

  useEffect(() => {
    triggerOrders(selectedCountry.id);
  }, [selectedCountry]);

  useEffect(() => {
    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });

    isSuccess && Loading.remove(500);
  }, [isSuccess]);

  useEffect(() => {
    setTableData([]);
    setSelectedPaymentMethod("ALL");
    setSelectedPaymentStatus("ALL");
    setSelectedBDM(0);
  }, [selectedCountry]);

  useEffect(() => {
    // Get the current date
    const currentDate = new Date();

    // Set endDate to the current date
    setEndDate(currentDate);

    // Calculate one month before the current date
    const oneMonthBefore = new Date(currentDate);
    oneMonthBefore.setMonth(currentDate.getMonth() - 1);

    // Set startDate to one month before the current date
    setStartDate(oneMonthBefore);
  }, [selectedCountry]);

  useEffect(() => {
    if (BDMList && BDMList.length > 0) {
      let combinedArray = [...BDMList];
      combinedArray.push({
        id: 0,
        email: "All",
        first_name: "All",
        last_name: "",
        active: 1,
        created_date: "",
        assigned_date: "",
        total_sales: 0,
      });
      setModifiedBDMArray(combinedArray.sort((a, b) => a.id - b.id));
    }
  }, [BDMList]);

  useEffect(() => {
    if (ordersList && ordersList.length > 0) {
      // let addingBDMRandom = ordersList.map(item => {
      //   return {
      //     ...item,
      //     item.bdm
      //   }
      // })

      if (Object.keys(notificationDetails).length > 0) {
        setSelectedBDM(0);
        setSelectedPaymentMethod("ALL");
        setSelectedPaymentStatus("ALL");
        setStartDate(null);
        setEndDate(null);
        if (notificationDetails.category == "ORDERS") {
          let allOrders = [...ordersList];
          let arrayIndex = allOrders.findIndex((item) => item.id == notificationDetails.id);
          if (arrayIndex > -1) {
            const [element] = allOrders.splice(arrayIndex, 1);
            allOrders.unshift(element);

            setTableData(allOrders);

            setTimeout(() => {
              const element = document.getElementById(`orderId${notificationDetails.id}`);
              if (element) {
                element.click();
              }
            }, 200);
          } else {
            const filteredData = ordersList.filter((order) => {
              var res =
                selectedPaymentMethod != "ALL"
                  ? order.payment_method == selectedPaymentMethod
                  : true;

              if (selectedPaymentStatus != "ALL") {
                res = res && order.payment_status == selectedPaymentStatus;
              }

              if (startDate) {
                res =
                  res &&
                  moment(order.datetime).format(dateFormat) >= moment(startDate).format(dateFormat);
              }
              if (endDate) {
                res =
                  res &&
                  moment(order.datetime).format(dateFormat) <= moment(endDate).format(dateFormat);
              }

              if (selectedBDM != 0) {
                res = res && order.bdm_id == selectedBDM;
              }
              // res = res && order.order_status === orderStatus;
              return res;
            });
            setTableData(filteredData);

            Notify.failure("Order not found!");
          }
        }
      } else {
        const filteredData = ordersList.filter((order) => {
          var res =
            selectedPaymentMethod != "ALL" ? order.payment_method == selectedPaymentMethod : true;

          if (selectedPaymentStatus != "ALL") {
            res = res && order.payment_status == selectedPaymentStatus;
          }

          if (startDate) {
            res =
              res &&
              moment(order.datetime).format(dateFormat) >= moment(startDate).format(dateFormat);
          }
          if (endDate) {
            res =
              res &&
              moment(order.datetime).format(dateFormat) <= moment(endDate).format(dateFormat);
          }

          if (selectedBDM != 0) {
            res = res && order.bdm_id == selectedBDM;
          }
          // res = res && order.order_status === orderStatus;
          return res;
        });
        setTableData(filteredData);
      }
    }
  }, [
    ordersList,
    startDate,
    endDate,
    selectedPaymentMethod,
    selectedPaymentStatus,
    selectedBDM,
    notificationDetails,
  ]);

  useEffect(() => {
    if (tableData.length > 0 && notificationDetails.id) {
      if (tableData[0].id != notificationDetails.id) {
        let allOrders = [...tableData];
        let arrayIndex = tableData.findIndex((item) => item.id == notificationDetails.id);
        if (arrayIndex > -1) {
          const [element] = allOrders.splice(arrayIndex, 1);
          allOrders.unshift(element);

          setTableData(allOrders);
        }
      }
      setTimeout(() => {
        const element = document.getElementById(`orderId${notificationDetails.id}`);
        if (element) {
          element.click();
        }
      }, 1000);
    }
  }, [tableData, notificationDetails]);

  useEffect(() => {
    if (selectedBDM != 0) {
      let filteredTotal = 0;
      let total = 0;
      let refundedAmount = 0;

      if (tableData && tableData.length > 0) {
        for (let single of tableData) {
          filteredTotal = filteredTotal + single.grandtotal;
          total = total + single.grandtotal;
          if (single.refunded_amount) {
            filteredTotal = filteredTotal - single.refunded_amount;
            refundedAmount = refundedAmount + single.refunded_amount;
          }
        }
      }

      setTotalSales(filteredTotal.toFixed(2));
      setOrderTotal(total.toFixed(2));
      setRefundedTotal(refundedAmount.toFixed(2));
    }
  }, [tableData, selectedBDM]);

  // useEffect(() => {
  //   // if(notificationDetails)
  //   if (tableData.length > 0 && notificationDetails)
  //     setTimeout(() => {
  //       if (notificationDetails.category == "ORDERS") {
  //         const element = document.getElementById(`orderId${notificationDetails.id}`);
  //         if (element) {
  //           element.click();
  //         }
  //       }
  //     }, 200);
  // }, [notificationDetails, tableData]);

  useEffect(() => {
    return () => {
      dispatch(resetForwardSection());
    };
  }, []);

  const cancelOrder = async (orderData) => {
    let invoiceNinjaAPI = selectedCountry?.invoiceNinjaAPI;

    let data = {};

    if (orderData.refunded_amount != null) {
      data.amount = (orderData.grandtotal - orderData.refunded_amount).toFixed(2);
    } else {
      data.amount = orderData.grandtotal;
    }

    data.reason = "Canceled by BDM";
    data.countryId = orderData.reseller.companies.country_id;
    data.orderId = orderData.id;
    data.paymentIntentId = orderData.payment_intent_id;
    data.resellerId = orderData.reseller_id;

    if (orderData.payment_method == "CARD") {
      if (data.paymentIntentId == "") {
        Notify.failure("Payment Intent Id is not found!");
      } else {
        try {
          Loading.dots({
            svgColor: "rgba(159, 3, 3, 0.8)",
          });
          refundOrder(data).then((response) => {
            if (response.data?.status == "success") {
              changePaymentStatus({
                order_id: orderData.id,
                status: "REFUNDED",
                invoiceNinjaAPI: invoiceNinjaAPI,
              }).then((responseStatus) => {
                if (responseStatus.data?.status == "success") {
                  let cancelData = {};
                  cancelData.invoiceNinjaAPI = invoiceNinjaAPI;
                  cancelOrderMutation({ orderId: orderData.id, data: cancelData }).then(
                    (cancelResponse) => {
                      if (cancelResponse.data?.status == "success") {
                        Notify.failure("Order has been successfully canceled!");
                      }
                    }
                  );
                }
              });
            }
          });
        } catch (e) {
        } finally {
          Loading.remove(500);
        }
      }
    } else {
      let cancelData = {};
      cancelData.invoiceNinjaAPI = invoiceNinjaAPI;
      try {
        Loading.dots({
          svgColor: "rgba(159, 3, 3, 0.8)",
        });
        cancelOrderMutation({ orderId: orderData.id, data: cancelData }).then((cancelResponse) => {
          if (cancelResponse.data?.status == "success") {
            Notify.failure("Order has been successfully canceled!");
          }
        });
      } catch (e) {
      } finally {
        Loading.remove(500);
      }
    }
  };

  return (
    <Card>
      <CardHeader
        // avatar={<AccountCircleIcon fontSize="large" color="info" />}
        title={<MDTypography color="info">Orders</MDTypography>}
        // action={
        //   <MDButton variant="gradient" color="dark">
        //     add
        //   </MDButton>
        // }
      />
      <CardContent>
        <br />
        <GridContainer>
          <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
            <DateTime
              label="Start date *"
              name="start_date"
              value={startDate}
              onChange={(e) => {
                setStartDate(e);
                dispatch(resetForwardSection());
              }}
              inputProps={{
                placeholder: "Pick a Start Date",
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
            <DateTime
              label="End date *"
              name="end_date"
              value={endDate}
              onChange={(e) => {
                setEndDate(e);
                dispatch(resetForwardSection());
              }}
              inputProps={{
                placeholder: "Pick a End Date",
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
            <CustomSelect
              label="Payment Method *"
              inputProps={{
                id: "method_id",
                name: "method_id",
              }}
              value={selectedPaymentMethod}
              onChange={(e) => {
                setSelectedPaymentMethod(e.target.value);
                dispatch(resetForwardSection());
              }}
              sx={{
                "& .MuiInputBase-root .MuiSelect-select": {
                  height: "44px",
                  // color: "#fff",
                  // fontWeight: "400",
                },
                "& .MuiFormLabel-root": {
                  backgroundColor: "#fff",
                  // color: "#e0e1e5",
                  padding: "0 5px",
                },
              }}
              // helperText={errors.country_id}
              // error={errors.country_id}
            >
              <MenuItem disabled>Payment Methods</MenuItem>
              {paymentMethods.map((prop, key) => (
                <MenuItem key={key} value={prop}>
                  {prop}
                </MenuItem>
              ))}
            </CustomSelect>
          </GridItem>
          <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
            <CustomSelect
              label="Payment Status *"
              inputProps={{
                id: "status_id",
                name: "status_id",
              }}
              value={selectedPaymentStatus}
              onChange={(e) => {
                setSelectedPaymentStatus(e.target.value);
                dispatch(resetForwardSection());
              }}
              sx={{
                "& .MuiInputBase-root .MuiSelect-select": {
                  height: "44px",
                  // color: "#fff",
                  // fontWeight: "400",
                },
                "& .MuiFormLabel-root": {
                  backgroundColor: "#fff",
                  // color: "#e0e1e5",
                  padding: "0 5px",
                },
              }}
              // helperText={errors.country_id}
              // error={errors.country_id}
            >
              <MenuItem disabled>Payment Status</MenuItem>
              {paymentStatuses.map((prop, key) => (
                <MenuItem key={key} value={prop}>
                  {prop}
                </MenuItem>
              ))}
            </CustomSelect>
          </GridItem>
          <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
            <CustomSelect
              label="Filter by BDM *"
              inputProps={{
                id: "bdm_id",
                name: "bdm_id",
              }}
              value={selectedBDM}
              onChange={(e) => {
                setSelectedBDM(e.target.value);
                dispatch(resetForwardSection());
              }}
              sx={{
                textTransform: "capitalize",
                "& .MuiInputBase-root .MuiSelect-select": {
                  height: "44px",
                  // color: "#fff",
                  // fontWeight: "400",
                },
                "& .MuiFormLabel-root": {
                  backgroundColor: "#fff",
                  // color: "#e0e1e5",
                  padding: "0 5px",
                },
              }}
              // helperText={errors.country_id}
              // error={errors.country_id}
            >
              <MenuItem disabled>BDMs</MenuItem>
              {modifiedBDMArray.map((prop, key) => (
                <MenuItem sx={{ textTransform: "capitalize" }} key={key} value={prop.id}>
                  {prop.first_name + " " + prop.last_name}
                </MenuItem>
              ))}
            </CustomSelect>
          </GridItem>
          <GridItem xs={12} sm={12} md={3} lg={3} xl={3}></GridItem>
          <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
            <GridContainer sx={{ mb: 2 }}>
              <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                {modifiedBDMArray && modifiedBDMArray.length > 0 && selectedBDM != 0 && (
                  <Card sx={{ height: "100%" }}>
                    <MDBox
                      pt={2}
                      px={2}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <MDTypography variant="h6" fontWeight="medium">
                        {modifiedBDMArray.filter((item) => item.id == selectedBDM)[0].first_name +
                          " " +
                          modifiedBDMArray.filter((item) => item.id == selectedBDM)[0]
                            .last_name}{" "}
                        {!modifiedBDMArray.filter((item) => item.id == selectedBDM)[0].active && (
                          <MDBadge
                            badgeContent="Inactive"
                            color="error"
                            variant="gradient"
                            size="sm"
                          />
                        )}
                      </MDTypography>
                    </MDBox>
                    <MDBox p={2}>
                      <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                        <MDBox
                          component="li"
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          py={1}
                          pr={1}
                        >
                          <MDBox lineHeight={1.125}>
                            <MDTypography display="block" variant="button" fontWeight="medium">
                              From {moment(startDate).format(dateFormat)} to{" "}
                              {moment(endDate).format(dateFormat)}
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                        <MDBox
                          component="li"
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          pr={1}
                        >
                          <MDBox lineHeight={1.125}>
                            <MDTypography variant="caption" fontWeight="regular" color="text">
                              Total
                            </MDTypography>
                          </MDBox>
                          <MDBox display="flex" alignItems="center">
                            <MDTypography variant="caption" fontWeight="regular" color="text">
                              {selectedCountry.currency} {orderTotal}
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                        {refundedTotal && refundedTotal != 0 && (
                          <MDBox
                            component="li"
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            pr={1}
                          >
                            <MDBox lineHeight={1.125}>
                              <MDTypography variant="caption" fontWeight="regular" color="text">
                                Refunded total
                              </MDTypography>
                            </MDBox>
                            <MDBox display="flex" alignItems="center">
                              <MDTypography variant="caption" fontWeight="regular" color="text">
                                {selectedCountry.currency} {refundedTotal}
                              </MDTypography>
                            </MDBox>
                          </MDBox>
                        )}
                        <MDBox
                          component="li"
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          py={1}
                          pr={1}
                          mb={1}
                        >
                          <MDBox lineHeight={1.125}>
                            <MDTypography variant="caption" fontWeight="regular" color="text">
                              Total sales
                            </MDTypography>
                          </MDBox>
                          <MDBox display="flex" alignItems="center">
                            <MDTypography
                              variant="button"
                              fontWeight="regular"
                              sx={{ fontSize: "1.25rem", color: "#494b53", fontWeight: 500 }}
                            >
                              {selectedCountry.currency} {totalSales}
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                      <MDBox>
                        {selectedPaymentMethod != "ALL" && (
                          <Chip
                            sx={{
                              height: "26px",
                              mr: 1,
                              "& .MuiChip-label": { fontSize: "0.65rem" },
                              "& .MuiSvgIcon-root": { marginTop: "-1px" },
                            }}
                            label={selectedPaymentMethod}
                            onDelete={() => setSelectedPaymentMethod("ALL")}
                          />
                        )}
                        {selectedPaymentStatus != "ALL" && (
                          <Chip
                            sx={{
                              height: "26px",
                              mr: 1,
                              "& .MuiChip-label": { fontSize: "0.65rem" },
                              "& .MuiSvgIcon-root": { marginTop: "-1px" },
                            }}
                            label={selectedPaymentStatus}
                            onDelete={() => setSelectedPaymentStatus("ALL")}
                          />
                        )}
                        {/* <Chip label="Deletable" onDelete={handleDelete} />
                    <Chip label="Deletable" variant="outlined" onDelete={handleDelete} /> */}
                      </MDBox>
                    </MDBox>
                  </Card>
                )}
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
        {tableData && tableData.length > 0 ? (
          <DataTable
            id={tables.ORDERS}
            headerActions={
              <Tooltip title="Refetch orders" sx={{ ml: 1 }} placement="top">
                <IconButton
                  aria-label="view"
                  size="small"
                  onClick={() => triggerOrders(selectedCountry.id)}
                >
                  <RestartAltIcon color="info" fontSize="inherit" />
                </IconButton>
              </Tooltip>
            }
            actionButton={
              <MDButton
                variant="gradient"
                color="info"
                sx={{ ml: 2 }}
                onClick={() => navigate.push(`/user/add-order`)}
              >
                Add order
              </MDButton>
            }
            columns={[
              {
                Header: "Order Id",
                accessor: "id",
              },
              {
                Header: "Contact Name",
                accessor: "contact_name",
                Cell: ({ row: { original } }) =>
                  original.reseller?.companies?.name ? (
                    <MDBox>
                      <MDBox>{original.contact_name}</MDBox>
                      <MDBox sx={{ fontWeight: 600, fontSize: "11px" }}>
                        {original.reseller?.companies?.name}
                      </MDBox>
                    </MDBox>
                  ) : (
                    <>
                      <>{original.contact_name}</>
                    </>
                  ),
              },
              {
                Header: "Date",
                accessor: "datetime",
                Cell: ({ row: { original } }) => moment(original.datetime).format(dateFormat),
              },
              {
                Header: "Payment Method",
                accessor: "payment_method",
                Cell: ({ row: { original } }) => (
                  <>
                    {original.payment_method == "CREDIT" ? (
                      <>Net 30 Day Account Credit</>
                    ) : (
                      <>{original.payment_method}</>
                    )}
                  </>
                ),
              },
              {
                Header: "Payment Status",
                accessor: "payment_status",
                Cell: ({ row: { original } }) =>
                  original.payment_status == "PENDING" ? (
                    <>
                      <MDBox>
                        <MDBadge badgeContent="Pending" color="info" variant="gradient" size="sm" />
                      </MDBox>
                    </>
                  ) : original.payment_status == "PAID" ? (
                    <MDBox>
                      <MDBadge badgeContent="Paid" color="success" variant="gradient" size="sm" />
                      {original.refunded_amount != null && (
                        <MDBadge badgeContent="P/R" color="error" variant="gradient" size="sm" />
                      )}
                    </MDBox>
                  ) : original.payment_status == "REFUNDED" ? (
                    <MDBox>
                      <MDBadge badgeContent="Refunded" color="error" variant="gradient" size="sm" />
                    </MDBox>
                  ) : (
                    <MDBox>
                      <MDBadge badgeContent="Canceled" color="error" variant="gradient" size="sm" />
                    </MDBox>
                  ),
              },
              {
                Header: "Total",
                accessor: "grandtotal",
                Cell: ({ row: { original } }) => (
                  <>
                    {selectedCountry.currency} {original.grandtotal?.toFixed(2)}
                  </>
                ),
              },
              {
                Header: "Actions",
                accessor: "actions",
                disableSortBy: true,
                disableFilters: true,
                show: true,
                align: "right",
                // eslint-disable-next-line react/display-name
                Cell: ({ row: { original } }) => (
                  <div className="actions-right">
                    {original.id == notificationDetails.id && (
                      <div
                        className="highlight-animation"
                        style={{
                          position: "absolute",
                          borderRadius: 5,
                          top: 0,
                          right: 0,
                          left: 0,
                          bottom: 0,
                        }}
                      ></div>
                    )}
                    <OrderDetails id={original.id} order={original} />
                    {!["REFUNDED", "CANCELED"].includes(original.payment_status) && original.payment_method != "CARD" && (
                      <Tooltip title="Cancel order" placement="top">
                        <IconButton
                          aria-label="view"
                          size="small"
                          onClick={() =>
                            Confirm.show(
                              `Please Confirm`,
                              "Are you sure you want to cancel this order?",
                              "Yes",
                              "No",
                              () => {
                                cancelOrder(original);
                              },
                              () => {},
                              {}
                            )
                          }
                        >
                          <CancelIcon color="error" fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                ),
              },
            ]}
            data={tableData || []}
            isSorted={true}
            entriesPerPage={false}
            showTotalEntries={false}
            hideColumnSelection={false}
            noEndBorder
            canSearch
            stateReducer={(newState, action) => {
              // console.log(newState);
              // console.log(action);
              if (action.type === "toggleRowSelected") {
                newState.selectedRowIds = {
                  [action.id]: action.value,
                };
              }
              return newState;
            }}
          />
        ) : (
          <Alert severity="warning">No Orders found!</Alert>
        )}
      </CardContent>
    </Card>
  );
}

export default Orders;
