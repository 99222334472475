/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import * as yup from "yup";

import axios from "axios";

import { Importer, ImporterField } from "react-csv-importer";

import { FilePond, registerPlugin } from "react-filepond";

import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { styled } from "@mui/material/styles";

// @mui material components
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import MDButton from "components/MDButton";

import { AddBox } from "@mui/icons-material";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";

import TextField from "@mui/material/TextField";

import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";

import MDTypography from "components/MDTypography";

import Typography from "@mui/material/Typography";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import ControlledTextInput from "components/FormInputs/ControlledTextInput";
import ControlledCheckbox from "components/FormInputs/ControlledCheckbox";
import CustomInputs from "components/CustomInputs";
import ControlledSelect from "components/FormInputs/ControlledSelect";

//components
import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import Tooltip from "@mui/material/Tooltip";

import Box from "@mui/material/Box";

import DialogActions from "@mui/material/DialogActions";

// theme CSS for React CSV Importer
import "react-csv-importer/dist/index.css";

// queries
import {
  useGetAllResellerProductsByCountryIdQuery,
  usePostProductMutation,
} from "services/products";

import { replaceSingleQuoteToSQLFriendly, dataBASE64URLtoFile } from "utils/helperFunctions";

// import { useGetAllProductsFromStoreUrlQuery } from "services/store";

import { globalCountry, selectedCountryURL } from "store/entities/countries";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const SelectAttributeFromPool = ({
  addAttributeFields,
  openSelectAttributeFromPool,
  setOpenSelectAttributeFromPool,
  closeAttributeMenu,
  distinctAttributes,
  existingAttributes,
}) => {
  const initialFormState = {
    attributes: [],
  };

  const { control, watch, handleSubmit, setValue, resetField, formState, reset } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        attributes: yup.array().of(yup.string()).required().min(1).label("One or more"),
      })
    ),
    defaultValues: initialFormState,
  });

  const handleSelectAttributeDialogClose = () => {
    setOpenSelectAttributeFromPool(false);
  };

  const onSubmit = (data) => {
    let array = [...existingAttributes];
    if (data && data.attributes && data.attributes.length > 0) {
      array.push(...data.attributes);
    }
    addAttributeFields([]);
    addAttributeFields([...new Set(array.filter((element) => element != ""))]);
    closeAttributeMenu(false);
    handleSelectAttributeDialogClose();
    // addAttributeFields
  };

  useEffect(() => {
    if (openSelectAttributeFromPool) reset({ attributes: existingAttributes });
  }, [openSelectAttributeFromPool]);

  return (
    <>
      <BootstrapDialog
        onClose={handleSelectAttributeDialogClose}
        aria-labelledby="customized-dialog-title"
        maxWidth="lg"
        sx={{
          "& .MuiPaper-root": {
            width: "550px",
          },
        }}
        open={openSelectAttributeFromPool}
      >
        <DialogTitle sx={{ m: 0, p: 2, color: "#4b4b4f" }} id="customized-dialog-title">
          Select Attributes
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleSelectAttributeDialogClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <form>
            <ControlledSelect
              multiple
              name={"attributes"}
              control={control}
              label="Select common attributes *"
              menu={{
                id: (prop) => prop,
                value: (prop) => prop,
                title: "Type",
                list: distinctAttributes,
              }}
              sx={{
                "& .MuiFormLabel-root": {
                  backgroundColor: "#fff",
                },
                "& .MuiInputBase-root": {
                  height: "44px",
                },
              }}
            />
          </form>
          <br />
        </DialogContent>
        <DialogActions>
          <MDButton
            type="submit"
            color="primary"
            variant="gradient"
            id="submit"
            onClick={handleSubmit(onSubmit)}
          >
            Add
          </MDButton>
          <MDButton
            color="secondary"
            variant="gradient"
            autoFocus
            onClick={handleSelectAttributeDialogClose}
          >
            Cancel
          </MDButton>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

export default SelectAttributeFromPool;
