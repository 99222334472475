import * as yup from "yup";

export const countriesSchema = yup.object().shape({
  name: yup.string().required().label("Country Name"),
  // currency: yup.string().required().label("Currency"),
  // phone_code: yup.string().required().label("Phone Code"),
  // url: yup.string().required().label("URL"),
  // consumer_key: yup.string().required().label("Consumer Key"),
  // consumer_secret: yup.string().required().label("Consumer Secret"),
  isTaxIncluded: yup.bool().label("Tax Included"),
  taxPercentage: yup.number().when(["isTaxIncluded"], {
    is: (isTaxIncluded) => isTaxIncluded === true,
    then: () => yup.number().min(1).required(),
    otherwise: () => yup.number()
  }).default(0).label("Tax Percentage"),
  deliveryCharges: yup.number().min(0).required().default("").label("Delivery Charges"),
  freeDeliveryAbove: yup.number().min(0).required().default("").label("Free Delivery Above"),
  expressShippingFee: yup.number().min(0).required().default("").label("Express Shipping Fee"),
  stripeSecretKey: yup.string().required().default("").label("Stripe SecretKey"),
  stripePublicKey: yup.string().required().default("").label("Stripe Public Key"),
  stripeWebhookSecret: yup.string().required().default("").label("Stripe Webhook Secret"),
  invoiceNinjaAPI: yup.string().required().default("").label("InvoiceNinja API Key"),
  urls: yup.array().of(
    yup.object().shape({
      url: yup.string()
      .matches(/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/, "Enter correct url!").required("URL is required"),
      cunsumer_key: yup.string().required("Consumer key is required"),
      cunsumer_secret: yup.string().required("Consumer secret is required"),
      label: yup.string().required("Label is required"),
    })
  ),
  regionalTaxes: yup.array().of(
    yup.object().shape({
      id: yup.number().min(0).required("State is required"),
      value: yup.number().min(0).required("Tax percentage is required")
    })
  ),
  // terms_and_conditions: yup.string().label("Terms and Condition"),
});
