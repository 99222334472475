/* eslint-disable react/prop-types */
import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";

import { Notify } from "notiflix/build/notiflix-notify-aio";
import { Confirm } from "notiflix/build/notiflix-confirm-aio";

import { styled } from "@mui/material/styles";

import PropTypes from "prop-types";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import MDButton from "components/MDButton";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import LockResetIcon from "@mui/icons-material/LockReset";

import MDBadge from "components/MDBadge";
import MDAvatar from "components/MDAvatar";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import CircularProgress from "@mui/material/CircularProgress";

import Button from "@mui/material/Button";

import DataTable from "examples/Tables/DataTable";

import { Loading } from "notiflix/build/notiflix-loading-aio";

import Alert from "components/CustomAlert";

import { useRequestPasswordResetMutation } from "services/resellers";

import { useLazyGetResellersByCompanyIdQuery, useDeleteResellerMutation } from "services/resellers";

import AddReseller from "./AddReseller";
import EditReseller from "./EditReseller";

import { imageSrcDecoder } from "utils/helperFunctions";

import noPreview from "assets/images/no-preview.png";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

import MDBox from "components/MDBox";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AvatarPreview = ({ avatarName, src }) => {
  const [imageLoading, setImageLoading] = useState(true);

  return (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      {imageLoading && (
        <CircularProgress
          color="primary"
          sx={{
            width: "10px",
            height: "10px",
          }}
          size="small"
        />
      )}
      <MDAvatar
        src={src}
        name={avatarName}
        onLoad={() => setImageLoading(false)}
        size="md"
      />
    </MDBox>
  )
}

const ResellersDetails = ({ company }) => {
  const [open, setOpen] = useState(false);

  const [
    getResellersByCompanyId,
    { data: resellersList = [], isLoading: isLoadingResellers, isSuccess: isSuccessResellers },
  ] = useLazyGetResellersByCompanyIdQuery();

  const [passwordReset, { isLoading }] = useRequestPasswordResetMutation();

  const [deleteReseller, result] = useDeleteResellerMutation();

  const handleClickOpen = () => {
    setOpen(true);

    getResellersByCompanyId(company.id);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const deleteRecord = (id) => {
    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });
    try {
      deleteReseller({ company_id: company.id, reseller_id: id })
        .then((response) => {
          if (response.data?.status == "success") {
            Notify.success("Succefully Deleted!");
          } else {
            Notify.failure("Something went wrong, Please try again!");
          }
        })
        .catch((error) => {
          console.log(error);
          Notify.failure("Something went wrong, Please try again!");
        });
    } catch (error) {
      console.log(error);
      Notify.failure("Something went wrong, Please try again!");
    } finally {
      Loading.remove(500);
    }
  };

  useEffect(() => {
    isLoadingResellers
      ? Loading.dots({
          svgColor: "rgba(159, 3, 3, 0.8)",
        })
      : Loading.remove(500);
  }, [isLoadingResellers]);

  return (
    <>
      <Tooltip title="View Resellers" placement="top">
        <MDButton
          variant="contained"
          size="small"
          color="dark"
          sx={{
            ml: 1,
            color: "#fff",
            fontSize: "10px!important",
            fontWeight: "500",
            letterSpacing: "0.5px",
          }}
          onClick={handleClickOpen}
        >
          Resellers
        </MDButton>
      </Tooltip>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        maxWidth="lg"
        sx={{
          "& .MuiPaper-root": {
            width: "950px",
          },
        }}
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2, color: "#4b4b4f" }} id="customized-dialog-title">
          Resellers of {company.name}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {resellersList.length > 0 ? (
            <DataTable
              columns={[
                {
                  Header: "Image",
                  accessor: "image",
                  Cell: ({ row: { original } }) => (
                    <AvatarPreview avatarName={original.first_name} src={original.avatar ? imageSrcDecoder(original.avatar)
                      : noPreview} />
                  ),
                },
                {
                  Header: "Id",
                  accessor: "id",
                },
                {
                  Header: "Role",
                  accessor: "",
                  Cell: ({ row: { original } }) =>
                    original.is_primary_user ? (
                      <MDBox ml={-1}>
                        <MDBadge
                          badgeContent="Admin"
                          color="success"
                          variant="gradient"
                          size="sm"
                        />
                      </MDBox>
                    ) : (
                      <MDBox ml={-1}>
                        <MDBadge badgeContent="User" color="dark" variant="gradient" size="sm" />
                      </MDBox>
                    ),
                },
                {
                  Header: "Name",
                  accessor: "",
                  Cell: ({ row: { original } }) => (
                    <>
                      {original.first_name} {original.last_name}
                    </>
                  ),
                },
                {
                  Header: "Email",
                  accessor: "email",
                },
                {
                  Header: "Phone",
                  accessor: "phone",
                },
                {
                  Header: "Address",
                  accessor: "",
                  Cell: ({ row: { original } }) => (
                    <>
                      {original.address1} {original.address2}
                    </>
                  ),
                },
                {
                  Header: "Actions",
                  accessor: "actions",
                  disableSortBy: true,
                  disableFilters: true,
                  show: true,
                  align: "right",
                  // eslint-disable-next-line react/display-name
                  Cell: ({ row: { original } }) => (
                    <div className="actions-right">
                      <Tooltip title="Reset password" placement="top">
                        <IconButton
                          aria-label="view"
                          size="small"
                          onClick={() => {
                            Loading.dots({
                              svgColor: "rgba(159, 3, 3, 0.8)",
                            });
                            try {
                              passwordReset({ email: original.email }).then((response) => {
                                if (response.data?.status == "success") {
                                  Notify.success("Password has been reset succefully");
                                } else {
                                  Notify.failure("Something went wrong!");
                                }
                              });
                            } catch (e) {
                              Notify.failure("Something went wrong!");
                              console.log(e);
                            } finally {
                            }
                            Loading.remove(500);
                          }}
                        >
                          <LockResetIcon color="dark" fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                      <EditReseller existingResellersList={resellersList} companyId={company.id} initData={original} />
                      {!original.is_primary_user ? (
                        <Tooltip title="Delete" sx={{ ml: 1 }} placement="top">
                          <IconButton
                            aria-label="view"
                            size="small"
                            onClick={() =>
                              Confirm.show(
                                `Please Confirm`,
                                "Are you sure you want to delete this reseller?",
                                "Yes",
                                "No",
                                () => {
                                  deleteRecord(original.id);
                                },
                                () => {},
                                {}
                              )
                            }
                          >
                            <CloseIcon color="error" fontSize="inherit" />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        ""
                      )}
                    </div>
                  ),
                },
              ]}
              data={resellersList || []}
              isSorted={true}
              entriesPerPage={false}
              showTotalEntries={false}
              hideColumnSelection={false}
              noEndBorder
              canSearch
              actionButton={<AddReseller companyId={company.id} />}
            />
          ) : (
            <Alert severity="warning">No Resellers found!</Alert>
          )}
        </DialogContent>
        <DialogActions>
          <MDButton color="secondary" variant="gradient" autoFocus onClick={handleClose}>
            Cancel
          </MDButton>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

ResellersDetails.propTypes = {
  children: PropTypes.node.isRequired, // You can also use PropTypes.element if you want to enforce a single element.
  contact: PropTypes.string.isRequired,
};

export default ResellersDetails;
