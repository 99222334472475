/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import axios from "axios";

import { styled } from "@mui/material/styles";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import MenuItem from "@mui/material/MenuItem";

import TextField from "@mui/material/TextField";

import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import { Confirm } from "notiflix/build/notiflix-confirm-aio";

// components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import MDAvatar from "components/MDAvatar";
import { CustomSelect } from "components/CustomSelect";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

// components
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

import Tooltip from "@mui/material/Tooltip";

import Edit from "@mui/icons-material/Edit";
import Close from "@mui/icons-material/Close";

import { actions as tableActions } from "store/ui/table";

// queries
import {
  useGetAllResellerProductsByCountryIdQuery,
  useGetAllResellerProductsQuery,
  useDeleteProductMutation,
} from "services/products";

import {
  useGetAllResellerProductCategoriesQuery,
  useGetWoocommerceSiteCategoriesMutation,
} from "services/categories";

import { useGetAllUrlsByCountryIdQuery } from "services/countries";

// import { useGetAllProductsFromStoreUrlQuery } from "services/store";

import {
  globalCountry,
  selectedCountryURL,
  setSelectedCountryURL,
  resetSelectedCountryURL,
} from "store/entities/countries";

import Alert from "components/CustomAlert";

import CircularProgress from "@mui/material/CircularProgress";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import noPreview from "assets/images/no-preview.png";

import EditCategoryDialog from "./EditCategoryDialog";
import AddCategoryDialog from "./AddCategoryDialog";

import { replaceHtmlEntities, addSpacesToNames } from "utils/helperFunctions";
import { tables } from "utils/constants";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function Inventory() {
  const selectedCountry = useSelector(globalCountry);

  const selectedURL = useSelector(selectedCountryURL);

  const [filterCategories, setFilterCategories] = useState([]);

  const navigate = useHistory();

  const dispatch = useDispatch();

  const {
    data: categoriesList = [],
    isLoading,
    isSuccess,
  } = useGetAllResellerProductCategoriesQuery(selectedCountry.id);

  // const {
  //   data: resellerProducts = [],
  //   isLoading: isResellerProductsLoading,
  //   isSuccess: isResellerProductsSuccess,
  // } = useGetAllResellerProductsQuery();

  const {
    data: urlsByCountryId = [],
    isLoading: isUrlsByCountryIdLoading,
    isSuccess: isUrlsByCountryIdSuccess,
  } = useGetAllUrlsByCountryIdQuery(selectedCountry.id);

  const [deleteProduct, result] = useDeleteProductMutation();

  const [fetchWoocommerceCategories] = useGetWoocommerceSiteCategoriesMutation();

  useEffect(() => {
    isLoading || isUrlsByCountryIdLoading
      ? Loading.dots({
          svgColor: "rgba(159, 3, 3, 0.8)",
        })
      : Loading.remove(500);
  }, [isLoading, isUrlsByCountryIdLoading]);

  useEffect(() => {
    if (categoriesList && categoriesList.length > 0) {
      if (selectedURL && selectedURL.id) {
        let filteredData = categoriesList.filter((item) => item.url_id == selectedURL.id);
        setFilterCategories(filteredData);
      } else {
        setFilterCategories([]);
      }
    }
  }, [categoriesList, selectedURL, selectedCountry]);

  const handleUrlChange = (id) => {
    if (id) {
      let selected = urlsByCountryId.urls.filter((item) => item.id == id);
      // console.log(selected);
      dispatch(
        setSelectedCountryURL({
          id: selected[0].id,
          url: selected[0].url,
          c_key: selected[0].cunsumer_key,
          c_secret: selected[0].cunsumer_secret,
        })
      );
    }
  };

  // const refetchWoocommerceCategories = (url, key, secret) => {
  //   fetchData(url, key, secret);
  // };

  useEffect(() => {
    return () => {
      dispatch(
        setSelectedCountryURL({
          id: 0,
          url: "",
          c_key: "",
          c_secret: "",
        })
      );
    };
  }, []);

  return (
    <Card>
      <CardHeader
        // avatar={<AccountCircleIcon fontSize="large" color="dark" />}
        title={<MDTypography color="info">Categories</MDTypography>}
        action={
          selectedURL && selectedURL.id ? (
            <AddCategoryDialog categories={addSpacesToNames(filterCategories)} urlID={selectedURL.id} />
          ) : (
            ""
          )
        }
      />
      <CardContent>
        <br />
        {isUrlsByCountryIdSuccess && selectedURL.url == "" ? (
          <Alert severity="warning">Please select the country URL!</Alert>
        ) : (
          ""
        )}
        {
          isUrlsByCountryIdSuccess && (
            <GridContainer>
              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                {selectedURL && (
                  <CustomSelect
                    label="Select URL *"
                    inputProps={{
                      id: "url_id",
                      name: "url_id",
                    }}
                    value={selectedURL.id}
                    onChange={(e) => handleUrlChange(e.target.value)}
                    sx={{
                      "& .MuiInputBase-root .MuiSelect-select": {
                        height: "44px",
                        // color: "#fff",
                        // fontWeight: "400",
                      },
                      "& .MuiFormLabel-root": {
                        backgroundColor: "#fff",
                        // color: "#e0e1e5",
                        padding: "0 5px",
                      },
                    }}
                    // helperText={errors.country_id}
                    // error={errors.country_id}
                  >
                    <MenuItem disabled>URLs</MenuItem>
                    {urlsByCountryId.urls.map((prop, key) => (
                      <MenuItem key={key} value={prop.id}>
                        {prop.url}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                )}
              </GridItem>
            </GridContainer>
          )
          // : (
          //   <Alert severity="warning">No urls found under the selected country!</Alert>
          // )
        }
        {selectedURL.url ? (
          <DataTable
            id={tables.CATEGORIES}
            // headerActions={
            //   <Tooltip title="Refetch Products from the store" sx={{ ml: 1 }} placement="top">
            //     <IconButton
            //       aria-label="view"
            //       size="small"
            //       onClick={() =>
            //         refetchWoocommerceCategories(
            //           selectedURL.url,
            //           selectedURL.c_key,
            //           selectedURL.c_secret
            //         )
            //       }
            //     >
            //       <RestartAltIcon color="info" fontSize="inherit" />
            //     </IconButton>
            //   </Tooltip>
            // }
            columns={[
              {
                Header: "Category Name",
                accessor: "name",
              },
              {
                Header: "Type",
                accessor: "type",
                Cell: ({ row: { original } }) => original.parent_id == 0 ? 'Parent' : 'Child',
              },
              {
                Header: "Display status",
                accessor: "active",
                Cell: ({ row: { original } }) => (
                  <div className="actions-center">
                    {original.active ? (
                      <MDBox ml={-1}>
                        <MDBadge badgeContent="Yes" color="success" variant="gradient" size="sm" />
                      </MDBox>
                    ) : (
                      <MDBox ml={-1}>
                        <MDBadge badgeContent="No" color="dark" variant="gradient" size="sm" />
                      </MDBox>
                    )}
                  </div>
                ),
              },
              {
                Header: "Actions",
                accessor: "actions",
                disableSortBy: true,
                disableFilters: true,
                show: true,
                align: "right",
                // eslint-disable-next-line react/display-name
                Cell: ({ row: { original } }) => (
                  <div className="actions-right">
                    <EditCategoryDialog
                      categoryData={{ ...original, url_id: selectedURL.id }}
                      categories={addSpacesToNames(filterCategories)}
                      urlID={selectedURL.id}
                    />
                    {/* {original.type == "old" ? (
                      <EditCategoryDialog categoryData={{ ...original, url_id: selectedURL.id }} />
                    ) : (
                      <AddCategoryDialog categoryData={{ ...original, url_id: selectedURL.id }} />
                    )} */}
                    {/* {original.type != "variation" && (
                      <Tooltip title="Delete" sx={{ ml: 1 }} placement="top">
                        <IconButton
                          aria-label="view"
                          size="small"
                          onClick={() => {
                            Confirm.show(
                              `Please Confirm`,
                              "Are you sure you want to delete?",
                              "Yes",
                              "No",
                              () => {
                                deleteRecord(original.id);
                              },
                              () => {},
                              {}
                            );
                          }}
                        >
                          <Close color="error" fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                    )} */}
                  </div>
                ),
              },
            ]}
            data={filterCategories}
            isSorted={true}
            entriesPerPage={false}
            showTotalEntries={false}
            hideColumnSelection={false}
            noEndBorder
            canSearch
          />
        ) : (
          ""
        )}
      </CardContent>
    </Card>
  );
}

export default Inventory;
