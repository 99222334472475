/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Notify } from "notiflix/build/notiflix-notify-aio";
import { Confirm } from "notiflix/build/notiflix-confirm-aio";

import { styled } from "@mui/material/styles";

import moment from "moment";

import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Block } from "notiflix/build/notiflix-block-aio";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";

import TextField from "@mui/material/TextField";

import Box from "@mui/material/Box";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { AddBox } from "@mui/icons-material";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Typography from "@mui/material/Typography";

import ControlledTextInput from "components/FormInputs/ControlledTextInput";
import ControlledDateTime from "components/FormInputs/ControlledDateTime";
import ControlledCheckbox from "components/FormInputs/ControlledCheckbox";
import ControlledSelect from "components/FormInputs/ControlledSelect";
import CustomInputs from "components/CustomInputs";
import { CustomSelect } from "components/CustomSelect";

//components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import MDBadge from "components/MDBadge";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import { BDMSchema } from "./schemas/_model";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import Check from "@mui/icons-material/Check";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from '@mui/material/FormControlLabel';

import Tooltip from "@mui/material/Tooltip";

import Edit from "@mui/icons-material/Edit";
import Close from "@mui/icons-material/Close";

import InputAdornment from "@mui/material/InputAdornment";

import Alert from "components/CustomAlert";

import EditBDM from "./EditBDM";
import AddBDM from "./AddBDM";

import { tables, dateFormat } from "utils/constants";

// rtk queries
import {
  useGetAllBDMWithInactiveQuery,
  useDeleteBDMMutation
} from "services/bdm";

import { globalCountry } from "store/entities/countries";

import "./assets/styles.css";

import { useDispatch, useSelector } from "react-redux";

import { replaceHTMLEntityAnd } from "utils/helperFunctions";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BDMs () {

  const selectedCountry = useSelector(globalCountry);

  const [withOldRecords, setWithOldRecords] = useState(true);

  const [tableData, setTableData] = useState([]);

  const { data: BDMList = [], isLoading, isSuccess } = useGetAllBDMWithInactiveQuery();

  const [deleteBDM, result] = useDeleteBDMMutation();

  const dispatch = useDispatch();

  useEffect(() => {
    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });

    isSuccess && Loading.remove(500);
  }, [isSuccess]);

  const deleteRecord = async (id) => {
    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });
    
    try {
      deleteBDM(id)
        .then((response) => {
          if(response.data?.status == "success") {
            Notify.success("Succefully deleted!");
          } else {
            if(response.error?.data) {
              Notify.failure(response.error.data.message);
            } else {
              Notify.failure("Something went wrong, Please try again!");
            }
          }
        })
        .catch((error) => console.log(error));
        
    } catch (error) {
      console.log(error);
    } finally {
      Loading.remove(500);
    }
  };

  useEffect(() => {
    if (BDMList.length > 0) {
      const filteredData = BDMList.filter((bdm) => {
        var res =
          withOldRecords != true && !bdm.active ? false : true;
        // res = res && order.order_status === orderStatus;
        return res;
      });
      setTableData(filteredData);
    }
  }, [BDMList, withOldRecords]);

  return (
    <Card>
      <CardHeader
        // avatar={<AccountCircleIcon fontSize="large" color="info" />}
        title={<MDTypography color="info">Business Developement Managers</MDTypography>}
      />
      <CardContent>
        <br />
        {/* {BDMList && BDMList.length > 0 ? ( */}
          <DataTable
            headerActions={
              <FormControlLabel control={
                <Checkbox
                checkedIcon={<Check />}
                icon={<Check />}
                onChange={(e) => setWithOldRecords(e.target.checked)}
                checked={withOldRecords}
              />
              } label="Including inactive" />
            }
            columns={[
              { 
                Header: "email", 
                accessor: "email",
              },
              { 
                Header: "name", 
                accessor: "name", 
                Cell: ({ row: { original } }) => <>{original.first_name + ' ' + original.last_name}</>
              },
              { 
                Header: "created date", 
                accessor: "created_date",
                Cell: ({ row: { original } }) => moment(original.created_date).format(dateFormat)
              },
              { 
                Header: "total sales", 
                accessor: "total_sales",
                // Cell: ({ row: { original } }) => {selectedCountry.currency + original.total_sales.toFixed(2)}
                Cell: ({ row: { original } }) => selectedCountry.currency + ' ' + original.total_sales.toFixed(2)
              },
              { 
                Header: "status", 
                accessor: "active",
                // Cell: ({ row: { original } }) => {selectedCountry.currency + original.total_sales.toFixed(2)}
                Cell: ({ row: { original } }) => original.active ? <MDBadge badgeContent="Active" color="success" variant="gradient" size="sm" /> : <MDBadge badgeContent="Inactive" color="error" variant="gradient" size="sm" />
              },
              {
                Header: "action",
                accessor: "action",
                align: "right",
                disableSortBy: true,
                disableFilters: true,
                show: true,
                // eslint-disable-next-line react/display-name
                Cell: ({ row: { original } }) => (
                  <div className="actions-right">
                    <EditBDM
                      disabled={!original.active}
                      bdmData={original}
                    />
                    <Tooltip title="Delete" sx={{ ml: 1 }} placement="top">
                      <IconButton
                        aria-label="view"
                        size="small"
                        disabled={!original.active}
                        onClick={() => {
                          Confirm.show(
                            `Please Confirm`,
                            "Are you sure you want to delete?",
                            "Yes",
                            "No",
                            () => {
                              deleteRecord(original.id);
                            },
                            () => {},
                            {}
                          );
                        }}
                      >
                        <Close color={original.active ? "error" : "text"} fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                  </div>
                ),
              },
            ]}
            data={tableData}
            isSorted={true}
            entriesPerPage={false}
            showTotalEntries={false}
            hideColumnSelection={false}
            noEndBorder
            canSearch
            actionButton={
              <AddBDM />
            }
          />
        {/* ) : (
          <Alert severity="warning">No Business Developement Managers found!</Alert>
        )} */}
      </CardContent>
    </Card>
  );
}

export default BDMs;
