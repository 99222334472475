/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Notify } from "notiflix/build/notiflix-notify-aio";
import { Confirm } from "notiflix/build/notiflix-confirm-aio";

import { styled } from "@mui/material/styles";

import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";

import { convertToHTML } from "draft-convert";

import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Block } from "notiflix/build/notiflix-block-aio";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";

import TextField from "@mui/material/TextField";

import Box from "@mui/material/Box";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { AddBox } from "@mui/icons-material";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Typography from "@mui/material/Typography";

import ControlledTextInput from "components/FormInputs/ControlledTextInput";
import ControlledDateTime from "components/FormInputs/ControlledDateTime";
import ControlledCheckbox from "components/FormInputs/ControlledCheckbox";
import ControlledSelect from "components/FormInputs/ControlledSelect";
import CustomInputs from "components/CustomInputs";
import { CustomSelect } from "components/CustomSelect";

//components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import MDBadge from "components/MDBadge";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import { countriesSchema } from "./schemas/_model";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import Tooltip from "@mui/material/Tooltip";

import Edit from "@mui/icons-material/Edit";
import Close from "@mui/icons-material/Close";

import InputAdornment from "@mui/material/InputAdornment";

import Alert from "components/CustomAlert";
import Divider from "@mui/material/Divider";

import EditCountry from "./EditCountry";

import Instructions from "./InstructionsDialog";
import AddCountry from './AddCountry'

import instructions from "./data/instructions";

import { tables } from "utils/constants";

// rtk queries
import {
  useGetAllCountriesQuery,
  useGetAllInactiveCountriesQuery,
  useLazyGetAllStatesByCountryIdQuery,
  useUpdateCountryMutation,
  useDeleteCountryMutation,
  useGetAllUrlsByCountryIdQuery,
} from "services/countries";

import {
  useGetWoocommerceSiteCategoriesMutation,
  usePostCategoryNameMutation,
} from "services/categories";

import {
  useGetWoocommerceSiteProductsMutation,
  usePostProductMutation,
  useUploadProductImageMutation,
} from "services/products";

import { getTempURLs, setTempURLs } from "store/entities/countries";

import {
  setGlobalLoader,
  setGlobalLoaderMessage,
  resetGlobalLoader,
  loaderStatus,
  globalLoaderMessage,
} from "store/ui/loader";

import "./assets/styles.css";
import { useDispatch, useSelector } from "react-redux";

import { removeLocalStorageItemsWithPrefix, replaceHTMLEntityAnd } from "utils/helperFunctions";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function Countries() {

  const { data: countries = [], isLoading, isSuccess } = useGetAllCountriesQuery();

  const [deleteCountry, result] = useDeleteCountryMutation();

  const dispatch = useDispatch();
  useEffect(() => {
    removeLocalStorageItemsWithPrefix("fetchedWoocommerceCategories=");
    removeLocalStorageItemsWithPrefix("savedWoocommerceCategories=");
    removeLocalStorageItemsWithPrefix("fetchedWoocommerceProducts=");

    dispatch(resetGlobalLoader());
    return () => {
      dispatch(setTempURLs([]));
    };
  }, []);

  useEffect(() => {
    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });

    isSuccess && Loading.remove(500);
  }, [isSuccess]);

  const tableData = useMemo(() => {
    if (countries.length > 0) {
      let data = countries.map((prop, key) => {
        return {
          id: prop.id,
          region: prop.name,
          currency: (
            <MDTypography component="p" href="#" variant="caption" color="text" fontWeight="medium">
              {prop.currency}
            </MDTypography>
          ),
          is_tax_included: prop.is_tax_included ? (
            <MDBox ml={-1}>
              <MDBadge badgeContent="Yes" color="success" variant="gradient" size="sm" />
            </MDBox>
          ) : (
            <MDBox ml={-1}>
              <MDBadge badgeContent="No" color="dark" variant="gradient" size="sm" />
            </MDBox>
          ),
          action: (
            <div className="actions-right">
              <Tooltip title="Edit" placement="top">
                <IconButton aria-label="view" size="small" onClick={() => {}}>
                  <Edit color="warning" fontSize="inherit" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete" sx={{ ml: 1 }} placement="top">
                <IconButton aria-label="view" size="small" onClick={() => {}}>
                  <Close color="error" fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </div>
          ),
        };
      });

      return data;
    } else {
      return [];
    }
  }, [countries]);

  const deleteRecord = async (id) => {
    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });
    try {
      deleteCountry(id, { active: false })
        .then((response) => console.log(response))
        .catch((error) => console.log(error));
    } catch (error) {
      console.log(error);
    } finally {
      Loading.remove(500);
    }
  };

  return (
    <Card>
      <CardHeader
        // avatar={<AccountCircleIcon fontSize="large" color="info" />}
        title={<MDTypography color="info">Countries</MDTypography>}
        // action={
        //   <MDButton variant="gradient" color="dark">
        //     add
        //   </MDButton>
        // }
      />
      <CardContent>
        <br />
        {countries.length > 0 ? (
          <DataTable
            id={tables.COUNTRIES}
            columns={[
              { Header: "region name", accessor: "region", width: "45%", align: "left" },
              { Header: "currency", accessor: "currency", align: "left" },
              { Header: "tax included", accessor: "is_tax_included", align: "center" },
              {
                Header: "action",
                accessor: "action",
                align: "right",
                disableSortBy: true,
                disableFilters: true,
                show: true,
                // eslint-disable-next-line react/display-name
                Cell: ({ row: { original } }) => (
                  <div className="actions-right">
                    <EditCountry
                      countryData={countries.filter((item) => item.id == original.id)[0]}
                    />
                    <Tooltip title="Delete" sx={{ ml: 1 }} placement="top">
                      <IconButton
                        aria-label="view"
                        size="small"
                        onClick={() => {
                          Confirm.show(
                            `Please Confirm`,
                            "Are you sure you want to delete?",
                            "Yes",
                            "No",
                            () => {
                              deleteRecord(original.id);
                            },
                            () => {},
                            {}
                          );
                        }}
                      >
                        <Close color="error" fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                  </div>
                ),
              },
            ]}
            data={tableData}
            isSorted={true}
            entriesPerPage={false}
            showTotalEntries={false}
            hideColumnSelection={false}
            noEndBorder
            canSearch
            actionButton={
              <AddCountry />
            }
          />
        ) : (
          <Alert severity="warning">No Products found!</Alert>
        )}
      </CardContent>
    </Card>
  );
}

export default Countries;
