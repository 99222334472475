/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Importer, ImporterField } from "react-csv-importer";

import { useForm, useFieldArray, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { styled } from "@mui/material/styles";

import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import MDButton from "components/MDButton";

import { AddBox } from "@mui/icons-material";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import { Block } from "notiflix/build/notiflix-block-aio";

import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import ControlledTextInput from "components/FormInputs/ControlledTextInput";
import ControlledDateTime from "components/FormInputs/ControlledDateTime";
import ControlledCheckbox from "components/FormInputs/ControlledCheckbox";
import ControlledSelect from "components/FormInputs/ControlledSelect";
import ControlledAutoComplete from "components/FormInputs/ControlledAutoComplete";
import CustomInputs from "components/CustomInputs";
import CustomAutoComplete from "components/CustomAutoComplete";

//components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import MDAvatar from "components/MDAvatar";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Hidden from '@mui/material/Hidden';
import DetailBlock from "components/DetailBlock";

//example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import Paper from '@mui/material/Paper';

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

import Box from "@mui/material/Box";

import Edit from "@mui/icons-material/Edit";
import Close from "@mui/icons-material/Close";

// theme CSS for React CSV Importer
import "react-csv-importer/dist/index.css";

// queries
import {
  useGetAllResellerProductsByCountryIdQuery,
  useLazyGetAllResellerProductsByCountryIdQuery,
  useUploadProductImageMutation,
  usePostProductMutation,
  useGetDistinctAttributesQuery
} from "services/products";

import {
  useGetAllStatesByCountryIdQuery
} from "services/countries";

import {
  useGetQuotationMutation,
  useCreateOrderMutation
} from "services/orders";

import { useGetAllResellerProductCategoriesQuery } from "services/categories";

import { useUploadFileMutation, useDeleteFileMutation } from "services/files";

// import { useGetAllProductsFromStoreUrlQuery } from "services/store";

import { globalCountry, setSelectedCountryURL } from "store/entities/countries";

import { useGetAllCompaniesQuery } from "services/companies";

import { useLazyGetResellersByCompanyIdQuery } from "services/resellers";

import TinyMCEEditor from "components/TinyMCEEditor";

import {
  replaceSingleQuoteToSQLFriendly,
  replaceHTMLEntityAnd,
  removeLocalStorageItemsWithPrefix,
  addSpacesToNames
} from "utils/helperFunctions";

import Alert from "components/CustomAlert";

import CircularProgress from "@mui/material/CircularProgress";

import ProductNode from "./ProductNode";

import { ArrowBack } from "@mui/icons-material";

import { addOrderSchema } from "../schemas/_model";

const QuotationPaper = styled(Paper)(({ theme }) => ({
  width: '100%',
  // height: 120,
  padding: theme.spacing(2),
  ...theme.typography.body2,
  textAlign: 'center',
}));

function AddOrder() {

  const initialFormState = {
    companyId: 0,
    resellerId: 0,
    sameAsDelivery: true,
    isExpressShipping: false,
    billingAddress: "",
    contactName: "",
    contactPhone: "",
    deliveryAddress: "",
    products: []
  };

  const navigate = useHistory();

  const selectedCountry = useSelector(globalCountry);

  const [file, setFile] = useState([]);

  const [quotation, setQuotation] = useState([]);

  const [quotationToken, setQuotationToken] = useState('');

  const [quotationStatus, setQuotationStatus] = useState(false);

  const [companyResellers, setCompanyResellers] = useState([]);

  const [subTotal, setSubTotal] = useState(0);

  const [physicalProductsExists, setPhysicalProductsExists] = useState();

  const dispatch = useDispatch();

  const { control, watch, handleSubmit, setValue, resetField, formState } = useForm({
    resolver: yupResolver(addOrderSchema),
    defaultValues: initialFormState,
  });

  const { fields, append, remove } = useFieldArray({ name: "products", control });

  const {
    data: productsList = [],
    isLoading: isProductsLoading,
    isSuccess: isProductsSuccess,
  } = useGetAllResellerProductsByCountryIdQuery(selectedCountry.id);

  const {
    data: companyList = [],
    isLoading: isCompaniesLoading,
    isSuccess: isCompaniesSuccess,
  } = useGetAllCompaniesQuery(selectedCountry.id);

  const [
    getResellersByCompanyId,
    { data: resellersList = [], isLoading: isLoadingResellers, isSuccess: isSuccessResellers },
  ] = useLazyGetResellersByCompanyIdQuery();

  const { data: statesByCountryId = [], isLoading: isLoadingStates, isSuccess: isSuccessStates } = useGetAllStatesByCountryIdQuery(selectedCountry.id);

  const sameAsDelivery = watch("sameAsDelivery");
  const state = watch("state");
  const productsInput = watch("products");
  const companyInput = watch("companyId");
  const expressShippingInput = watch("isExpressShipping");

  const [uploadBankReceipt] = useUploadFileMutation();

  const [getQuotation] = useGetQuotationMutation();

  const [createOrder] = useCreateOrderMutation();

  const onSubmit = async (data) => {
    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    })

    if(file && file.length > 0) {
      let formData = new FormData();
      formData.append("file", file);
      let bankReceiptResponse = await uploadBankReceipt({
        formData: formData,
        directory: "reciepts",
      });
      if (bankReceiptResponse.data.status == "success") {
        data.paymentProof = bankReceiptResponse.data?.response.path.replace(/\\/g, "/");
      } else {
        data.paymentProof = bankReceiptResponse.data?.response.path.replace(/\\/g, "/");
      }
    } else {
      data.paymentProof = "";
    }

    await fetchQuotationData();

    if(data.billingAddress) {
      
    } {
      data.billingAddress = data.deliveryAddress;
    }

    if(quotationToken) {
      data.token = quotationToken;
    }

    data.paymentIntentId = undefined;
    data.quantity = parseInt(data.quantity);
    data.paymentMethod = "BANK";
    data.paymentStatus = "PENDING";
    data.countryId = selectedCountry.id;

    delete data.products;
    delete data.sameAsDelivery;

    // console.log(data);

    const orderResponse = await createOrder(data);

    if(orderResponse) {
      console.log(orderResponse);
      if(orderResponse.error && orderResponse.error.data && orderResponse.error.data.message) {
        Notify.failure(orderResponse.error.data.message);
      } else {
        Notify.success("Successfully Added!")
        navigate.push("/user/orders");
      }
    }
    
    Loading.remove(500);
  };

  useEffect(() => {
    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });

    isProductsSuccess && isSuccessStates && isCompaniesSuccess && Loading.remove(500);
  }, [isProductsSuccess, isSuccessStates, isCompaniesSuccess]);

  const handleUpdateFiles = (files) => {
    if (files.length > 0) {
      let photos = [];
      for (let file of files) {
        photos.push(file.source);
      }
      setFile(photos);
    } else {
      setFile([]);
    }
  };

  const addProductNode = () => {
    append();
  };

  const removeProductNode = (index) => {
    remove(index);
    setQuotation([]);
    setQuotationToken('');
  };

  const fetchQuotationData = async () => {
    if(state && productsInput && companyInput) {
      const data = {};
      data.products = productsInput;
      data.companyId = companyInput;
      data.isExpressShipping = expressShippingInput;
      data.state = state;
      if(data.products && data.products.length > 0) {
        let formatedProducts = [];
        for(let formatedProduct of data.products) {
          let singleProduct = {};
          if(formatedProduct.variation) {
            singleProduct.id = formatedProduct.variation;
            
            let selectFromProductList = productsList.filter(item => item.id == formatedProduct.variation);
            if(selectFromProductList && selectFromProductList.length > 0) {
              singleProduct.isDigitalGood = selectFromProductList[0].is_digital_good;

              let parentProduct = productsList.filter(item => item.id == selectFromProductList[0].parent_id);
              if(parentProduct && parentProduct.length > 0) {
                singleProduct.name = parentProduct[0].name;
              } else {
                singleProduct.name = selectFromProductList[0].name;
              }

              if(selectFromProductList[0].products_images) {
                if(selectFromProductList[0].products_images.length > 0) {
                  if(selectFromProductList[0].products_images[0].src) {
                    singleProduct.image = selectFromProductList[0].products_images[0].src;
                  }
                }
              }

              if(selectFromProductList[0].variation_atribute_option) {
                let attributes = [];
                if(selectFromProductList[0].variation_atribute_option) {
                  if(selectFromProductList[0].variation_atribute_option.length > 0) {
                    for(let attribute of selectFromProductList[0].variation_atribute_option) {
                      if(attribute.products_attribute_options) {
                        attributes.push(attribute.products_attribute_options.option);
                      }
                    }
                  }
                }
                singleProduct.attributes = attributes;
              }
            }

            singleProduct.parentId = formatedProduct.product;
            singleProduct.isSimpleProduct = false;
          } else {
            singleProduct.id = formatedProduct.product;

            let selectFromProductList = productsList.filter(item => item.id == formatedProduct.product);
            if(selectFromProductList && selectFromProductList.length > 0) {
              singleProduct.isDigitalGood = selectFromProductList[0].is_digital_good;
              singleProduct.name = selectFromProductList[0].name;
              if(selectFromProductList[0].products_images) {
                if(selectFromProductList[0].products_images.length > 0) {
                  if(selectFromProductList[0].products_images[0].src) {
                    singleProduct.image = selectFromProductList[0].products_images[0].src;
                  }
                }
              }
            }

            singleProduct.attributes = [""];
            singleProduct.parentId = 0;
            singleProduct.isSimpleProduct = true;
          }

          singleProduct.quantity = parseInt(formatedProduct.qty);
          formatedProducts.push(singleProduct);
        }
        delete data.products;
        data.products = formatedProducts;
        data.countryId = selectedCountry.id;
      }
      Block.dots('#quotationElement', { svgColor: "rgba(159, 3, 3, 0.8)" });
      const quotaionResponse = await getQuotation(data);

      if(quotaionResponse && quotaionResponse.data) {
        setQuotation(quotaionResponse.data.response);
        if(quotaionResponse.data.response.products) {
          let products = quotaionResponse.data.response.products;
          let subTotal = 0;
          for(let product of products) {
            subTotal += product.display_price * parseInt(product.quantity);
          }
          setSubTotal(subTotal.toFixed(2));
        }
        setQuotationToken(quotaionResponse.data.response.token);

        setQuotationStatus(false);

        Block.remove('#quotationElement', 2000);
      }
      // setQuotation();
    } else {
      Notify.failure("Please select company and the delivery address state");
    }
  }

  useEffect(() => {
    if(state) fetchQuotationData();
  }, [state, expressShippingInput]);

  useEffect(() => {
    const fetchResellers = async () => {
      if(companyInput) {
        if(companyList.filter(item => item.id == companyInput) && companyList.filter(item => item.id == companyInput).length > 0 && companyList.filter(item => item.id == companyInput)[0].assigned_bdms.length > 0) {
          Block.dots('#resellerElement', { svgColor: "rgba(159, 3, 3, 0.8)" });
          const resellersResponse = await getResellersByCompanyId(companyInput);
          if(resellersResponse.data && resellersResponse.data.length > 0) {
            setCompanyResellers(resellersResponse.data);
            Block.remove('#resellerElement', 2000);
          }
        } else {
          Notify.failure("Selected company has no assigned BDMs, Please assign a BDM first!");
        }
      } else {
        setCompanyResellers([]);
      }
    }

    fetchResellers();
  }, [companyInput]); 
  
  useEffect(() => {
    if(productsInput && productsInput.length > 0) {
      setQuotationStatus(false);
    }
  }, [productsInput]);

  useEffect(() => {
    const subscription = watch((data) => {
        if (productsList && productsList.length > 0 && data.products && data.products.length > 0) {
            const physicalProductExists = data.products.some(product => {
                const productId = product.product || product.variation;
                if (productId) {
                    const matchingProduct = productsList.find(item => item.id === productId);
                    return matchingProduct && !matchingProduct.isDigitalGood;
                }
                return false;
            });
            setPhysicalProductsExists(physicalProductExists);
        } else {
            setPhysicalProductsExists(false);
        }
    });
    return () => {
        subscription.unsubscribe();
    };
}, [watch, productsList]);

  return (
    <Card>
      <CardHeader
        // avatar={<AccountCircleIcon fontSize="large" color="dark" />}
        title={<MDTypography color="info">Add custom order</MDTypography>}
        action={
          <IconButton color={"secondary"} onClick={() => navigate.goBack()} size="large">
            <ArrowBack />
          </IconButton>
        }
      />
      <CardContent>
        <form>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
              <MDBox
                sx={{
                  border: "1px solid #d2d6da",
                  borderRadius: "0.375rem",
                  mb: 3,
                  pt: 2,
                  position: "relative",
                  "& .tox-tinymce": { border: "none", position: "relative" },
                }}
              >
                <MDTypography
                  component="p"
                  color="info"
                  sx={{
                    lineHeight: "1.5",
                    fontSize: "0.8rem",
                    fontWeight: 500,
                    transform: "translate(7px, -50%)",
                    backgroundColor: "#fff",
                    color: "#7b809a",
                    padding: "0 5px",
                    position: "absolute",
                    left: 0,
                    top: 0,
                    zIndex: 1,
                  }}
                >
                  Products
                </MDTypography>
                <MDBox>
                  <Tooltip title="Add product node" placement="right">
                    <MDButton
                      color="info"
                      variant="gradient"
                      iconOnly
                      size='medium'
                      onClick={() => addProductNode()}
                      sx={{
                        mb: 2,
                        ml: 1
                      }}
                    >
                      <AddBox sx={{}} />
                    </MDButton>
                  </Tooltip>
                </MDBox>
                {fields.map((input, index) => {
                  return (
                    <MDBox sx={{ mx: 1 }} key={index}>
                      <ProductNode index={index} productsList={productsList} setValue={setValue} watch={watch} resetField={resetField} control={control} removeProductNode={removeProductNode} state={state} setQuotationStatus={setQuotationStatus} />
                    </MDBox>
                  );
                })}
                {quotationStatus && <MDTypography
                  component="p"
                  color="error"
                  sx={{
                    fontSize: "0.8rem",
                    fontWeight: 500,
                    px: 2,
                    mb: 1
                    // color: "#ed3434"
                  }}
                >
                  Current quotation is not updated, please regenerate 
                  <Tooltip title="Regenerate Quotation" placement="top">
                    <IconButton
                      aria-label="view"
                      size="small"
                      onClick={() => {
                        // if(formState.errors) {
                        //   Notify.failure("Please fill out the form in order to regenerate!");
                        // } else {
                        //   fetchQuotationData();
                        // }
                        fetchQuotationData();
                      }}
                    >
                      <RestartAltIcon color="info" fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </MDTypography>}
              </MDBox>
            </GridItem>
            {productsInput && productsInput.length > 0 ? <>
              <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
              <MDBox
                sx={{
                  border: "1px solid #d2d6da",
                  borderRadius: "0.375rem",
                  mb: 3,
                  pt: 2,
                  position: "relative",
                  "& .tox-tinymce": { border: "none", position: "relative" },
                }}
              >
                <MDTypography
                  component="p"
                  color="info"
                  sx={{
                    lineHeight: "1.5",
                    fontSize: "0.8rem",
                    fontWeight: 500,
                    transform: "translate(7px, -50%)",
                    backgroundColor: "#fff",
                    color: "#7b809a",
                    padding: "0 5px",
                    position: "absolute",
                    left: 0,
                    top: 0,
                    zIndex: 1,
                  }}
                >
                  Address details
                </MDTypography>
                <GridContainer sx={{ px: 1, mt: 1, pb: 1 }}>
                  <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                    <ControlledAutoComplete 
                      id="companyId"
                      name="companyId"
                      control={control}
                      options={companyList}
                      optionLabelProperty="name"
                      label="Company"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6} lg={6} xl={6} id='resellerElement'>
                    <ControlledAutoComplete 
                      id="resellerId"
                      name="resellerId"
                      control={control}
                      options={companyResellers}
                      optionLabelProperty="email"
                      label="Reseller"
                      disabled={companyResellers.length == 0}
                    />
                  </GridItem>
                  {companyInput ? <>
                    <GridItem xs={12} sm={12} md={6} lg={6} xl={6} sx={{ mt: 2 }}>
                    <ControlledTextInput
                      name="contactName"
                      control={control}
                      labelText="Contact Name *"
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6} lg={6} xl={6} sx={{ mt: 2 }}>
                    <ControlledTextInput
                      name="contactPhone"
                      control={control}
                      labelText="Contact Phone *"
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={8} lg={8} xl={8}>
                    <ControlledTextInput
                      name="deliveryAddress"
                      control={control}
                      labelText="Delivery Address *"
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                    <ControlledSelect
                      name={"state"}
                      control={control}
                      label="Select state *"
                      menu={{
                        id: "id",
                        value: "state_name",
                        title: "States",
                        list: statesByCountryId,
                      }}
                      defaultValue=""
                      sx={{
                        "& .MuiFormLabel-root": {
                          backgroundColor: "#fff",
                        },
                        "& .MuiInputBase-root": {
                          height: "44px",
                        },
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ControlledCheckbox name="sameAsDelivery" control={control} label="Billing address is same as delivery address?" />
                  </GridItem>
                  {physicalProductsExists && <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ControlledCheckbox name="isExpressShipping" control={control} label="Express shipping" />
                  </GridItem>}
                  </> : ''}
                  
                  {!sameAsDelivery && <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ControlledTextInput
                      name="billingAddress"
                      control={control}
                      labelText="Billing Address *"
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>}
                </GridContainer>
              </MDBox>
            </GridItem>
            <GridItem xs={12} sm={12}>
              <>
                <Box
                  component={FilePond}
                  sx={{
                    fontFamily: '"Poppins", "Roboto", "Helvetica", "Arial", sans-serif',
                    "& .filepond--file-action-button": {
                      cursor: "pointer",
                    },
                    "& .filepond--drop-label.filepond--drop-label label": {
                      fontSize: "13px",
                      letterSpacing: "0.5px",
                      fontWeight: "600",
                    },
                    mt: 2,
                  }}
                  instantUpload={false}
                  allowFileSizeValidation={true}
                  name="file"
                  allowFileTypeValidation={true}
                  acceptedFileTypes={[
                    "image/jpeg",
                    "image/jpg",
                    "image/png",
                    "application/msword", // for .doc
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // for .docx
                    "application/pdf",
                  ]}
                  allowMultiple={true}
                  allowFilePoster={false}
                  labelIdle={
                    'Drag & Drop bank receipt or <span class="filepond--label-action">Browse</span>'
                  }
                  onupdatefiles={handleUpdateFiles}
                  allowRevert={false}
                />
              </>
            </GridItem>
            {quotationToken && quotation && <GridItem xs={12} sm={12} md={12} lg={12} id="quotationElement">
              <QuotationPaper variant="outlined" sx={{ width: '97%' }}>
              <MDBox
                sx={{
                  border: "1px solid #d2d6da",
                  borderRadius: "0.375rem",
                  mb: 3,
                  pt: 2,
                  mt: 1,
                  position: "relative",
                  "& .tox-tinymce": { border: "none", position: "relative" },
                }}
              >
                <MDTypography
                  component="h6"
                  variant="h6"
                  color="info"
                  sx={{
                    lineHeight: "1.5",
                    fontSize: "0.8rem",
                    fontWeight: 500,
                    transform: "translate(7px, -50%)",
                    backgroundColor: "#fff",
                    color: "#7b809a",
                    padding: "0 5px",
                    position: "absolute",
                    left: 0,
                    top: 0,
                    zIndex: 1,
                  }}
                >
                  Quotation <Tooltip title="Regenerate Quotation" placement="top">
                    <IconButton
                      aria-label="view"
                      size="small"
                      onClick={() => fetchQuotationData()}
                    >
                      <RestartAltIcon color="info" fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </MDTypography>
                <GridContainer sx={{ px: 1, mt: 1 }}>
                  <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                    <MDBox
                      sx={{
                        border: "1px solid #d2d6da",
                        borderRadius: "0.375rem",
                        mb: 3,
                        pt: 2,
                        px: 2,
                        position: "relative",
                        "& .tox-tinymce": { border: "none", position: "relative" },
                        '& .MuiTypography-body2': {
                          fontSize: '15px',
                          fontWeight: 400
                        }
                      }}
                    >
                      <MDTypography
                        component="p"
                        color="info"
                        sx={{
                          lineHeight: "1.5",
                          fontSize: "0.8rem",
                          fontWeight: 500,
                          transform: "translate(7px, -50%)",
                          backgroundColor: "#fff",
                          color: "#7b809a",
                          padding: "0 5px",
                          position: "absolute",
                          left: 0,
                          top: 0,
                          zIndex: 1
                        }}
                      >
                        Products
                      </MDTypography>
                      {quotation.products && quotation.products.length > 0 && quotation.products.map(item => <DetailBlock key={item.id} blockTitle={<MDBox sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography component="p" variant="body2">
                          {productsList.filter(product => product.id == item.id)[0]?.type == "simple" ? productsList.filter(product => product.id == item.id)[0]?.name : productsList.filter(product => product.id == productsList.filter(product => product.id == item.id)[0]?.parent_id)[0]?.name }({productsList.filter(product => product.id == item.id)[0]?.type != "simple" && productsList.filter(product => product.id == item.id)[0]?.name}) x {item.quantity}
                        </Typography>
                        <Typography component="p" variant="body2" sx={{ fontWeight: 500 }}>
                          {`${selectedCountry.currency} ${item.price ? item.display_price.toFixed(2) : ''}`}
                        </Typography>
                      </MDBox>} blockDesc="" />)}
                      
                      <DetailBlock blockTitle={<MDBox sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography component="p" variant="body2">
                          Subtotal
                        </Typography>
                        <Typography component="p" variant="body2">
                          {`${selectedCountry.currency} ${subTotal}`}
                        </Typography>
                      </MDBox>} blockDesc="" />
                    </MDBox>
                    <MDBox
                      sx={{
                        border: "1px solid #d2d6da",
                        borderRadius: "0.375rem",
                        mb: 3,
                        pt: 2,
                        px: 2,
                        position: "relative",
                        "& .tox-tinymce": { border: "none", position: "relative" },
                        '& .MuiTypography-body2': {
                          fontSize: '15px',
                          fontWeight: 400
                        }
                      }}
                    >
                      <MDTypography
                        component="p"
                        color="info"
                        sx={{
                          lineHeight: "1.5",
                          fontSize: "0.8rem",
                          fontWeight: 500,
                          transform: "translate(7px, -50%)",
                          backgroundColor: "#fff",
                          color: "#7b809a",
                          padding: "0 5px",
                          position: "absolute",
                          left: 0,
                          top: 0,
                          zIndex: 1,
                        }}
                      >
                        Price breakdown
                      </MDTypography>
                      {quotation.delivery_fee ? <DetailBlock blockTitle={<MDBox sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography component="p" variant="body2">
                          Delivery fee
                        </Typography>
                        <Typography component="p" variant="body2">
                          {`${selectedCountry.currency} ${quotation.delivery_fee.toFixed(2)}`}
                        </Typography >
                      </MDBox>} blockDesc="" /> : <DetailBlock blockTitle={<MDBox sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography component="p" variant="body2">
                          Free shipping
                        </Typography>
                        <Typography component="p" variant="body2">
                          
                        </Typography >
                      </MDBox>} blockDesc="" />}
                      {quotation.total_discount > 0 && <DetailBlock blockTitle={<MDBox sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography component="p" variant="body2">
                          Discount
                        </Typography>
                        <Typography component="p" variant="body2">
                          - {`${selectedCountry.currency} ${quotation.total_discount.toFixed(2)}`}
                        </Typography>
                      </MDBox>} blockDesc="" />}
                      <DetailBlock blockTitle={<MDBox sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography component="p" variant="body2">
                          GST (10%)
                        </Typography>
                        <Typography component="p" variant="body2">
                          {`${selectedCountry.currency} ${quotation.total_tax ? quotation.total_tax.toFixed(2) : ''}`}
                        </Typography >
                      </MDBox>} blockDesc="" />
                      <DetailBlock blockTitle={<MDBox sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography component="p" variant="body2">
                          Total
                        </Typography>
                        <Typography component="p" variant="body2">
                          {`${selectedCountry.currency} ${quotation.grand_total ? quotation.grand_total.toFixed(2) : ''}`}
                        </Typography>
                      </MDBox>} blockDesc="" />
                    </MDBox>
                  </GridItem>
                </GridContainer>
              </MDBox>
              {/* <GridContainer>
                <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                  <DetailBlock blockTitle="Order ID" blockDesc={`#${fetchedOrderDetails.id}`} />
                </GridItem>
              </GridContainer> */}
              </QuotationPaper>
            </GridItem>}
            </> : <GridItem xs={12} sm={12}><MDTypography component="h6" variant="h6" color="info">You must add at least one product in order to fill the delivery details!</MDTypography></GridItem> }
          </GridContainer>
        </form>
        <CardActions disableSpacing>
          * Required fields
          <Box sx={{ marginLeft: "auto" }}>
            <MDButton
              color="primary"
              variant="gradient"
              id="submit"
              onClick={handleSubmit(onSubmit)}
              disabled={productsInput.length == 0 || !quotationToken || companyResellers.length == 0}
            >
              Add order
            </MDButton>
            <MDButton
              color="secondary"
              variant="gradient"
              onClick={() => navigate.goBack()}
              sx={{ ml: 1 }}
            >
              Cancel
            </MDButton>
          </Box>
        </CardActions>
      </CardContent>
    </Card>
  );
}

export default AddOrder;
