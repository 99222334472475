/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import axios from "axios";

import { styled } from "@mui/material/styles";

import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";

import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import Edit from "@mui/icons-material/Edit";

import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import CustomTabs from "components/CustomTabs";

import { globalCountry } from "store/entities/countries";

import noPreview from "assets/images/no-preview.png";

import {
  useLazyGetAllResellerProductsByCountryIdQuery,
  useGetWoocommerceSiteProductsMutation,
  useReorderProductsMutation,
  useReorderProductsWithoutAutoRefetchMutation,
} from "services/products";

import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

import { stripHTMLTags } from "utils/helperFunctions";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

const AddToReOrderList = ({
  openProductReorderDialog,
  setOpenProductReorderDialog,
  reorderProducts,
  currentProduct,
  currentProductId,
  currentPrority,
  setReorderProducts,
}) => {
  const [variationProductData, setVariationProductData] = useState([]);

  const [simpleProductData, setSimpleProductData] = useState({});

  const [fetchedVariationProductData, setFetchedVariationProductData] = useState([]);

  const [imageLoading, setImageLoading] = useState(true);

  const selectedCountry = useSelector(globalCountry);

  // const [reorderProductMutaion] = useReorderProductsMutation();

  const [reorderProductMutaion] = useReorderProductsWithoutAutoRefetchMutation();

  const addToReorderProducts = async (currentProductId) => {
    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });

    let sortDescReorderProducts = reorderProducts.sort((a, b) => b.priority - a.priority);
    let maxPriority = 0;

    if(sortDescReorderProducts && sortDescReorderProducts.length > 0) {
      maxPriority = sortDescReorderProducts[0].priority;
    } else {
      maxPriority = 1;
    }

    let data = {
      productId: currentProductId,
      priority: maxPriority
    };

    try {
      let response = await reorderProductMutaion([data]);

      if (response.data?.status == "success") {
        Notify.success("Successfully added!");
        setReorderProducts(prevState => [...prevState, currentProduct])
      } else {
        Notify.failure("Failed to add!");
      }
    } catch (error) {
      console.log("Error adding to reorder list", error);
    } finally {
      setTimeout(() => {
        Loading.remove(500);
      }, 500);
    }
  };

  const removeFromReorderProducts = async (currentProductId) => {
    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });

    let data = {
      productId: currentProductId,
      priority: 0,
    };

    try {
      let response = await reorderProductMutaion([data]);

      if (response.data?.status == "success") {
        Notify.success("Successfully removed!");
        let modifiedProducts = reorderProducts.filter(product => product.id != currentProductId);
        setReorderProducts(modifiedProducts);
      } else {
        Notify.failure("Failed to remove!");
      }
    } catch (error) {
      console.log("Error adding to reorder list", error);
    } finally {
      setTimeout(() => {
        Loading.remove(500);
      }, 500);
    }
  };

  return (
    <>
      <Tooltip
        title={reorderProducts.filter(product => product.id == currentProductId) && reorderProducts.filter(product => product.id == currentProductId).length == 0 ? "Add To Reorder Products" : "Remove From Reorder Products"}
        placement="top"
      >
        {reorderProducts.filter(product => product.id == currentProductId) && reorderProducts.filter(product => product.id == currentProductId).length == 0 ? (
          <IconButton
            aria-label="view"
            size="small"
            onClick={() => addToReorderProducts(currentProductId)}
          >
            <FormatListNumberedIcon color="secondary" fontSize="inherit" />
          </IconButton>
        ) : (
          <IconButton
            aria-label="view"
            size="small"
            onClick={() => removeFromReorderProducts(currentProductId)}
          >
            <FormatListBulletedIcon color="primary" fontSize="inherit" />
          </IconButton>
        )}
      </Tooltip>
    </>
  );
};

AddToReOrderList.propTypes = {
  // children: PropTypes.node.isRequired, // You can also use PropTypes.element if you want to enforce a single element.
  // productData: PropTypes.object.isRequired,
};

export default AddToReOrderList;
