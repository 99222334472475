/* eslint-disable react/prop-types */
import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";

import moment from "moment";

import { styled } from "@mui/material/styles";

import PropTypes from "prop-types";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import MDButton from "components/MDButton";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";

import MDBadge from "components/MDBadge";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import Button from "@mui/material/Button";

import DataTable from "examples/Tables/DataTable";

import { Loading } from "notiflix/build/notiflix-loading-aio";

import Alert from "components/CustomAlert";

import { dateFormat } from "utils/constants";

import { useRefundHistoryMutation } from "services/orders";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

import MDBox from "components/MDBox";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const RefundHistory = ({ orderId, tableData, currrency }) => {
  const [openRefundHistory, setOpenRefundHistory] = useState(false);

  const handleDialogOpen = () => {
    setOpenRefundHistory(true);
  };

  const handleDialogClose = () => {
    setOpenRefundHistory(false);
  };

  const modifiedTableData = useMemo(() => {
    if (tableData)
      if (tableData.length > 0) {
        let data = tableData.map((prop, key) => {
          return {
            id: orderId,
            reason: prop.reason,
            created: moment(prop.datetime).format(dateFormat),
            amount: prop.amount,
          };
        });

        return data;
      } else {
        return [];
      }
  }, [tableData]);

  return (
    <>
      <Tooltip title="View History" placement="top">
        <MDButton
          variant="contained"
          size="small"
          color="primary"
          sx={{
            ml: 1,
            color: "#fff",
            fontSize: "10px!important",
            fontWeight: "400",
          }}
          onClick={handleDialogOpen}
        >
          Refund History
        </MDButton>
      </Tooltip>
      <BootstrapDialog
        // onClose={handleDialogClose}
        aria-labelledby="customized-dialog-title"
        maxWidth="lg"
        sx={{
          "& .MuiPaper-root": {
            width: "850px",
          },
        }}
        open={openRefundHistory}
      >
        <DialogTitle sx={{ m: 0, p: 2, color: "#4b4b4f" }} id="customized-dialog-title">
          Refund history of #{orderId}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleDialogClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {modifiedTableData && modifiedTableData.length > 0 ? (
            <DataTable
              columns={[
                {
                  Header: "Order Id",
                  accessor: "id",
                  // Cell: ({ row: { original } }) => original.metadata.orderId,
                  Cell: ({ row: { original } }) => orderId,
                },
                {
                  Header: "Date",
                  accessor: "datetime",
                  Cell: ({ row: { original } }) => moment(original.datetime).format(dateFormat),
                },
                {
                  Header: "Reason",
                  accessor: "reason",
                  Cell: ({ row: { original } }) => original.reason,
                },
                {
                  Header: "Amount",
                  accessor: "amount",
                  Cell: ({ row: { original } }) => (
                    <>
                      <span style={{ textTransform: "uppercase" }}>{currrency}</span>{" "}
                      {original.amount}
                    </>
                  ),
                },
                // {
                //   Header: "Public Company?",
                //   accessor: "is_public_company",
                //   Cell: ({ row: { original } }) =>
                //     original.is_public_company ? (
                //       <MDBox ml={-1}>
                //         <MDBadge badgeContent="Yes" color="success" variant="gradient" size="sm" />
                //       </MDBox>
                //     ) : (
                //       <MDBox ml={-1}>
                //         <MDBadge badgeContent="No" color="dark" variant="gradient" size="sm" />
                //       </MDBox>
                //     ),
                // },
              ]}
              data={modifiedTableData || []}
              isSorted={true}
              entriesPerPage={false}
              showTotalEntries={false}
              hideColumnSelection={false}
              noEndBorder
              canSearch
            />
          ) : (
            <Alert severity="warning">No History found!</Alert>
          )}
        </DialogContent>
        <DialogActions>
          <MDButton color="secondary" variant="gradient" autoFocus onClick={handleDialogClose}>
            Cancel
          </MDButton>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

RefundHistory.propTypes = {
  tableData: PropTypes.array,
  orderId: PropTypes.number.isRequired,
};

export default RefundHistory;
