/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Notify } from "notiflix/build/notiflix-notify-aio";
import { Confirm } from "notiflix/build/notiflix-confirm-aio";

import { styled } from "@mui/material/styles";

import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";

import { convertToHTML } from "draft-convert";

import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Block } from "notiflix/build/notiflix-block-aio";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";

import TextField from "@mui/material/TextField";

import Box from "@mui/material/Box";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { AddBox } from "@mui/icons-material";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Typography from "@mui/material/Typography";

import ControlledTextInput from "components/FormInputs/ControlledTextInput";
import ControlledDateTime from "components/FormInputs/ControlledDateTime";
import ControlledCheckbox from "components/FormInputs/ControlledCheckbox";
import ControlledSelect from "components/FormInputs/ControlledSelect";
import CustomInputs from "components/CustomInputs";
import { CustomSelect } from "components/CustomSelect";

//components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import MDBadge from "components/MDBadge";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import { countriesSchema } from "../schemas/_model";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import Tooltip from "@mui/material/Tooltip";

import Edit from "@mui/icons-material/Edit";
import Close from "@mui/icons-material/Close";

import InputAdornment from "@mui/material/InputAdornment";

import Alert from "components/CustomAlert";
import Divider from "@mui/material/Divider";

import Instructions from "../InstructionsDialog";

import instructions from "../data/instructions";

import { tables } from "utils/constants";

// rtk queries
import {
  useGetAllCountriesQuery,
  useGetAllInactiveCountriesQuery,
  useLazyGetAllStatesByCountryIdQuery,
  useUpdateCountryMutation,
  useDeleteCountryMutation,
  useGetAllUrlsByCountryIdQuery,
  useLazyGetAllUrlsByCountryIdQuery
} from "services/countries";

import {
  useGetWoocommerceSiteCategoriesMutation,
  usePostCategoryNameMutation,
} from "services/categories";

import {
  useGetWoocommerceSiteProductsMutation,
  usePostProductMutation,
  useUploadProductImageMutation,
} from "services/products";

import { getTempURLs, setTempURLs } from "store/entities/countries";

import {
  setGlobalLoader,
  setGlobalLoaderMessage,
  resetGlobalLoader,
  loaderStatus,
  globalLoaderMessage,
} from "store/ui/loader";

import "../assets/styles.css";
import { useDispatch, useSelector } from "react-redux";

import { removeLocalStorageItemsWithPrefix, replaceHTMLEntityAnd } from "utils/helperFunctions";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function AddCountry() {

  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

  const [convertedContent, setConvertedContent] = useState(null);

  const [open, setOpen] = useState(false);

  const [selectedCountryUrls, setSelectedCountryUrls] = useState([]);

  const [updateCountry, { isLoading: isUpdateCountryLoading }] = useUpdateCountryMutation();

  const tempURLs = useSelector(getTempURLs);

  const dispatch = useDispatch();

  let savedCategories = [];
  // let savedProducts = [];

  // const [categoriesToLocalStorage, setCategoriesToLocalStorage] = useState([]);

  const [
    triggerStatesByCountryId,
    { data: statesByCountryId = [], isLoading: isLoadingStates, isSuccess: isSuccessStates },
  ] = useLazyGetAllStatesByCountryIdQuery();

  const [uploadProductImage] = useUploadProductImageMutation();

  const [postCategory] = usePostCategoryNameMutation();

  const [postProduct] = usePostProductMutation();

  // const {data: urlsByCountryId = [], isLoading: isLoadingUrls, isSuccess: isSuccessUrls } = useGetAllUrlsByCountryIdQuery();

  const {
    data: inactiveCountries = [],
    isLoading: isInactiveCountriesLoading,
    isSuccess: isInactiveCountriesSuccess,
  } = useGetAllInactiveCountriesQuery();

  const [
    triggerUrlsByCountryId,
    { data: urlsByCountryId = [], isLoading: isLoadingUrls, isSuccess: isSuccessUrls },
  ] = useLazyGetAllUrlsByCountryIdQuery();

  const [fetchWoocommerceCategories] = useGetWoocommerceSiteCategoriesMutation();

  const [fetchWoocommerceProducts] = useGetWoocommerceSiteProductsMutation();

  const [fetchWoocommerceVaiations] = useGetWoocommerceSiteProductsMutation();

  const initialFormState = {
    name: "",
    // currency: "",
    // url: "",
    // phone_code: "",
    // consumer_key: "",
    // consumer_secret: "",
    isTaxIncluded: true,
    deliveryCharges: 0,
    freeDeliveryAbove: 0,
    expressShippingFee: 0,
    taxPercentage: 0,
    stripeSecretKey: "",
    stripePublicKey: "",
    stripeWebhookSecret: "",
    invoiceNinjaAPI: "",
    regionalTaxes: [],
    urls: []
  };

  const { control, watch, handleSubmit, setValue, reset } = useForm({
    resolver: yupResolver(countriesSchema),
    defaultValues: initialFormState,
  });

  const { fields, append, remove } = useFieldArray({ name: "urls", control });

  const { fields: stateFields, append: appendState, remove: removeState } = useFieldArray({ name: "regionalTaxes", control });

  const isTaxIncluded = watch("isTaxIncluded");

  const countryField = watch("name");

  const handleClickOpen = () => {
    setOpen(true);

    if(fields && fields.length == 0) addFields();
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    let html = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(html);
  }, [editorState]);

  const onSubmit = async (data) => {

    if(data.urls.length == 0) {
      Notify.failure("You must add at least one url");
      return;
    }

    if(!isTaxIncluded) {
      if(data.regionalTaxes.length == 0) {
        Notify.failure("You must add at least one tax percentage");
        return;
      }
    } else {
      data.regionalTaxes = [];
    }

    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    })

    // dispatch(setGlobalLoaderMessage("Getting things ready!"));

    // dispatch(setGlobalLoader());

    inactiveCountries.map((item) => {
      if (item.id == data.name) {
        data.regNoTerm = item.reg_no_term != null ? item.reg_no_term : "sss";
        data.taxNoTerm = item.tax_no_term != null ? item.tax_no_term : "sss";
      }
    });

    // dispatch(setTempURLs(dynamicFields));

    data.termsAndConditions = convertedContent;

    let id = parseInt(data.name);
    data.id = id;

    data.countryId = id;

    // deleting unnecessary stuff
    delete data.name;
    delete data.terms_and_conditions;

    // let insertedCountryData = {};

    // console.log(data);

    try {
      updateCountry({ countryId: id, data: data }).then((response) => {
        if (response.error) {
          Notify.failure(response.error.data.message);
        } else {
          // dispatch(setGlobalLoaderMessage("The country has been added!"));

          setTimeout(() => {
            // dispatch(resetGlobalLoader());
            Notify.success("The country has been added!");
          }, 1000);
        }
      });
    } catch (e) {
      console.log(e);
    } finally {
      setTimeout(() => {
        Loading.remove(500);
      }, 500);
      handleClose();
    }

    // const ifURLsValid = await validateURLs(dynamicFields);

    // if (!ifURLsValid) {
    //   dispatch(resetGlobalLoader());

    //   Notify.failure("Please check the urls again..");
    // } else {
    //   dispatch(setGlobalLoaderMessage("All the urls are valid! Inseting the country data..."));

    //   // <-------------------------------------------------------------------- Adding the country

    //   try {
    //     updateCountry({ countryId: id, data: data }).then((response) => {
    //       if (response.error) {
    //         Notify.failure(response.error.data.message);
    //       } else {
    //         dispatch(setGlobalLoaderMessage("The country has been added!"));

    //         setTimeout(() => {
    //           dispatch(resetGlobalLoader());
    //           Notify.success("The country has been added!");
    //         }, 1000);
    //       }
    //     });
    //   } catch (e) {
    //     console.log(e);
    //   } finally {
    //     Loading.remove(500);
    //     handleClose();
    //   }
  

    //   // <-------------------------------------------------------------------- Fetching categories from the woocommerce site...

    //   // setTimeout(() => {
    //   //   dispatch(setGlobalLoaderMessage("Fetching categories from the woocommerce site..."));
    //   // }, 1000);

    //   // const areCategoriesFetchedFromWoocommerceSite = await fetchCategoriesFromWoocommerce(
    //   //   dynamicFields
    //   // );

    //   // if (!areCategoriesFetchedFromWoocommerceSite) {
    //   //   dispatch(resetGlobalLoader());

    //   //   removeLocalStorageItemsWithPrefix("fetchedWoocommerceCategories=");
    //   //   removeLocalStorageItemsWithPrefix("fetchedWoocommerceProducts=");
    //   //   removeLocalStorageItemsWithPrefix("savedWoocommerceCategories=");

    //   //   Notify.failure("Please check consumer key and secret, and try again!");
    //   // } else {
    //   //   setTimeout(() => {
    //   //     dispatch(setGlobalLoaderMessage("Importing categories..."));
    //   //   }, 1000);

    //   //   for (const field of insertedCountryData.urls) {
    //   //     let getStoredCategories = JSON.parse(
    //   //       localStorage.getItem(`fetchedWoocommerceCategories=${field.url}`)
    //   //     );

    //   //     // <-------------------------------------------------------------------- saving categories from the woocommerce site...

    //   //     savedCategories = [];
    //   //     // console.log(insertedCountryData);
    //   //     const ifCategoriesSaved = await sendPostCategoriesSequentially(
    //   //       getStoredCategories,
    //   //       field.id,
    //   //       field.url
    //   //     );
    //   //   }
    //   // }

      
    //   // <-------------------------------------------------------------------- Fetching products from the woocommerce site...
    //   // setTimeout(() => {
    //   //   dispatch(setGlobalLoaderMessage("Fetching products from the woocommerce site..."));
    //   // }, 1000);

    //   // const areProductsFetchedFromWoocommerceSite = await fetchProductsFromWoocommerce(
    //   //   dynamicFields
    //   // );

    //   // if (!areProductsFetchedFromWoocommerceSite) {
    //   //   dispatch(resetGlobalLoader());

    //   //   removeLocalStorageItemsWithPrefix("fetchedWoocommerceCategories=");
    //   //   removeLocalStorageItemsWithPrefix("fetchedWoocommerceProducts=");
    //   //   removeLocalStorageItemsWithPrefix("savedWoocommerceCategories=");

    //   //   Notify.failure("Please check consumer key and secret, and try again!");
    //   // } else {
    //   //   setTimeout(() => {
    //   //     dispatch(setGlobalLoaderMessage("Fetching product variations...."));
    //   //   }, 1000);

    //   //   for (const field of insertedCountryData.urls) {
    //   //     let fetchedWoocommerceProducts = JSON.parse(
    //   //       localStorage.getItem(`fetchedWoocommerceProducts=${field.url}`)
    //   //     );

    //   //     let variationCount = 0;

    //   //     for (const count of fetchedWoocommerceProducts) {
    //   //       if (count.type != "simple") variationCount++;
    //   //     }

    //   //     // console.log(insertedCountryData);
    //   //     const ifVariablesSaved = await fetchProductVariationsFromWoocommerce(
    //   //       fetchedWoocommerceProducts,
    //   //       field,
    //   //       variationCount
    //   //     );

    //   //     if (ifVariablesSaved) {
    //   //       dispatch(setGlobalLoaderMessage("Fetching product variations.... Done!"));

    //   //       setTimeout(() => {
    //   //         dispatch(setGlobalLoaderMessage("Saving fetched products..."));
    //   //       }, 1000);

    //   //       let savedCategories = JSON.parse(
    //   //         localStorage.getItem(`savedWoocommerceCategories=${field.url}`)
    //   //       );

    //   //       let modifiedFetchedWoocommerceProducts = JSON.parse(
    //   //         localStorage.getItem(`fetchedWoocommerceProducts=${field.url}`)
    //   //       );

    //   //       // <-------------------------------------------------------------------- saving products

    //   //       const ifProductsSaved = await sendPostProductsSequentially(
    //   //         savedCategories,
    //   //         modifiedFetchedWoocommerceProducts,
    //   //         field.id,
    //   //         insertedCountryData.id
    //   //       );

    //   //       if(ifProductsSaved) {
    //   //         dispatch(setGlobalLoaderMessage("Saved successfully!"));

    //   //         setTimeout(() => {
    //   //           dispatch(setGlobalLoaderMessage("Good to go.."));

    //   //           removeLocalStorageItemsWithPrefix("fetchedWoocommerceCategories=");
    //   //           removeLocalStorageItemsWithPrefix("fetchedWoocommerceProducts=");
    //   //           removeLocalStorageItemsWithPrefix("savedWoocommerceCategories=");

    //   //           setTimeout(() => {
    //   //             dispatch(resetGlobalLoader());
    //   //             Notify.success("All the categories and products added successfully!");
    //   //           }, 1000);

    //   //         }, 1000);
    //   //       }
    //   //     }
    //   //   }
    //   // } 

    //   //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // }

    // try {
    //   updateCountry({ countryId: id, data: data }).then((response) => {
    //     console.log(response);
    //     if (response.error) {
    //       Notify.failure(response.error.data.message);
    //     } else {
    //       Notify.success("Succefully Added!");
    //     }
    //   });
    // } catch (e) {
    //   console.log(e);
    // } finally {
    //   Loading.remove(500);
    //   handleClose();
    // }
  };

  const sendPostCategoriesSequentially = async (getStoredCategories, id, url) => {
    try {
      let allRequestsSuccessful = true;

      const categoriesArray = Array.isArray(getStoredCategories)
        ? getStoredCategories
        : Object.values(getStoredCategories);

      for (const category of categoriesArray) {
        let data = {};
        data.woocommerceId = category.id;
        data.urlId = id;
        data.parentId = category.parent;
        data.name = replaceHTMLEntityAnd(category.name);
        data.active = true;

        try {
          const response = await postCategory(data);

          // Check if the response is successful based on your API's structure
          if (!response.data.response) {
            // console.error(`Error posting category "${category.name}". Status: ${response.status}`);
            allRequestsSuccessful = false;
          } else {
            savedCategories.push(response.data.response);
            // console.log(`Category "${category.name}" posted successfully`);

            if (category.children && category.children.length > 0) {
              let payloadData = category.children.map((child) => ({
                ...child,
                parent: response.data.response.id,
              }));

              const childSuccess = await sendPostCategoriesSequentially(
                payloadData,
                id, // Assuming the inserted ID is available in the response
                url
              );

              // Update the overall success status based on child success
              allRequestsSuccessful = allRequestsSuccessful && childSuccess;
            }
          }
        } catch (error) {
          console.error(`Error posting category "${category.name}":`, error);
          allRequestsSuccessful = false;
        }
      }

      if (savedCategories.length > 0)
        localStorage.setItem(`savedWoocommerceCategories=${url}`, JSON.stringify(savedCategories));
      return allRequestsSuccessful;
    } catch (error) {
      console.error("Error sending POST requests:", error);
      return false;
    }
  };
  // savedCategories, modifiedFetchedWoocommerceProducts, field.id, insertedCountryData.id
  const sendPostProductsSequentially = async (
    savedCategories,
    modifiedFetchedWoocommerceProducts,
    urlId,
    countryId
  ) => {
    try {
      let allRequestsSuccessful = true;
      let savedProducts = [];

      let productsCount = 0;

      const productsArray = Array.isArray(modifiedFetchedWoocommerceProducts)
        ? modifiedFetchedWoocommerceProducts
        : Object.values(modifiedFetchedWoocommerceProducts);

      
      for (const product of productsArray) {
        if(product.status == 'publish') {
          const filteredIds = savedCategories
          .filter((savedCategory) =>
            product.categories.some(
              (woocommerceCategory) => woocommerceCategory.id === savedCategory.woocommerce_id
            )
          )
          .map((savedCategory) => savedCategory.id);

        // getting wholesale price as the reseller price
        let resellerPrice = 0;

        if(product.meta_data && product.meta_data.length > 0) {
          for(let meta_data of product.meta_data) {
            if(meta_data.key && meta_data.key == "wholesale_customer_wholesale_price") {
              resellerPrice = parseFloat(meta_data.value);
            }
          }
        }

        let isDigitalProduct = false;

        if(product.shipping_class == "physical") {
          isDigitalProduct = false;
        } else if (product.shipping_class == "digital") {
          isDigitalProduct = true;
        } else {
          if(product.virtual) {
            isDigitalProduct = product.virtual;
          }
        }

        let data = {
          woocommerceId: product.id,
          parentId: 0,
          countryId: countryId,
          isDigitalGood: isDigitalProduct,
          inStock: product.stock_status == "instock" ? true : false,
          price: resellerPrice ? resellerPrice : parseFloat(product.price),
          retailPrice: product.regular_price ? parseFloat(product.regular_price) : 0,
          isFeaturedProduct: false,
          urlId: urlId,
          digitalKeys: isDigitalProduct && (product.stock_status == "instock") ? ["Pending", "Pending", "Pending", "Pending", "Pending"] : [],
          images: [],
          attributes: [],
          categories: filteredIds,
          averageRating: product.average_rating ? parseFloat(product.average_rating) : 5,
          description: product.description ? product.description : '...',
          name: replaceHTMLEntityAnd(product.name),
          ratingCount: product.rating_count,
          shortDescription: product.short_description ? product.short_description : "...",
          sku: product.sku,
          type: product.type,
        };

        setTimeout(() => {
          dispatch(
            setGlobalLoaderMessage(
              `Saving fetched products... ${productsCount}/${productsArray.length}`
            )
          );
        }, 1000);

        const response = await postProduct(data);

        if (response.data?.response) {
          if (product.images && product.images.length > 0) {
            // setTimeout(() => {
            //   dispatch(setGlobalLoaderMessage(`Saving fetched product images...`));
            // }, 1000);
            let imageArray = [];
            for (let image of product.images) {
              imageArray.push(`${image.src}`);
            }
            let data = {
                imageUrls: imageArray,
              }
            const imageResponse = uploadProductImage({id: response.data.response.id, data});
  
            // if (!imageResponse.data.response) {
            //     // console.error(`Error posting category "${category.name}". Status: ${response.status}`);
            //     dispatch(resetGlobalLoader());
            //     allRequestsSuccessful = false;
            //     return false;
            // }
          }
        }

        // Check if the response is successful based on your API's structure
        if (!response.data.response) {
          // console.error(`Error posting category "${category.name}". Status: ${response.status}`);
          allRequestsSuccessful = false;
        } else {
          productsCount++;
          if (
            product.productVariations &&
            product.productVariations.length > 0 &&
            response.data?.response?.id
          ) {
            for (let variation of product.productVariations) {
              let isVariationDigitalProduct = false;

              if(variation.status == "publish") {
                let variationAttributes = [];

                if(variation.attributes && variation.attributes.length > 0) {
                  for (let attribute of variation.attributes) {
                    let attributeData = {};
                    attributeData.attribute = attribute.name;
                    attributeData.option = attribute.option;
                    variationAttributes.push(attributeData);
                  }
                }

                if(variation.shipping_class) {
                  if(variation.shipping_class == "physical") {
                    isVariationDigitalProduct = false;
                  } else if (variation.shipping_class == "digital") {
                    isVariationDigitalProduct = true;
                  } else {
                    if(variation.virtual) {
                      isVariationDigitalProduct = variation.virtual;
                    }
                  }
                } else {
                  if(variation.virtual) {
                    isVariationDigitalProduct = variation.virtual;
                  } else {
                    isVariationDigitalProduct = isDigitalProduct;
                  }
                }

                // getting wholesale price as the reseller price
                let resellerVariationPrice = 0;

                if(variation.meta_data && variation.meta_data.length > 0) {
                  for(let meta_data of variation.meta_data) {
                    if(meta_data.key && meta_data.key == "wholesale_customer_wholesale_price") {
                      resellerVariationPrice = parseFloat(meta_data.value);
                    }
                  }
                }

                let variationData = {
                  woocommerceId: variation.id,
                  parentId: response.data.response.id,
                  countryId: countryId,
                  isDigitalGood: isVariationDigitalProduct,
                  inStock: variation.stock_status == "instock" ? true : false,
                  price: resellerVariationPrice ? resellerVariationPrice : variation.price ? parseFloat(variation.price) : 0,
                  retailPrice: variation.regular_price ? parseFloat(variation.regular_price) : 0,
                  isFeaturedProduct: false,
                  urlId: urlId,
                  digitalKeys: (isVariationDigitalProduct && variation.stock_status == "instock") ? ["Pending", "Pending", "Pending", "Pending", "Pending"] : [],
                  images: [],
                  attributes: variationAttributes,
                  categories: filteredIds,
                  averageRating: variation.average_rating ? parseFloat(variation.average_rating) : 5,
                  description: variation.description ? variation.description : product.description ? product.description : '...',
                  name: variation.name ? replaceHTMLEntityAnd(variation.name) : replaceHTMLEntityAnd(product.name),
                  ratingCount: variation.rating_count ? variation.rating_count : product.rating_count,
                  shortDescription: variation.short_description ? variation.short_description : product.short_description ? product.short_description : '...',
                  sku: variation.sku ? variation.sku : "",
                  type: variation.type ? variation.type : product.type,
                };

                const variationResponse = await postProduct(variationData);

                if (!variationResponse.data.response) {
                  // console.error(`Error posting category "${category.name}". Status: ${response.status}`);
                  allRequestsSuccessful = false;
                } else {
                  if (variation.image) {
                    let data = {
                        imageUrls: [`${variation.image.src}`],
                      }
                    const variationImageResponse = uploadProductImage({id: variationResponse.data.response.id, data});
          
                    // if (!imageResponse.data.response) {
                    //     // console.error(`Error posting category "${category.name}". Status: ${response.status}`);
                    //     dispatch(resetGlobalLoader());
                    //     allRequestsSuccessful = false;
                    //     return false;
                    // }
                  }
                }
              }
            }
          }
          
          savedProducts.push(response.data.response);
        }
        }        
      }

      // if (savedProducts.length > 0)
      //   localStorage.setItem(`savedWoocommerceCategories=${url}`, JSON.stringify(savedProducts));
      return allRequestsSuccessful;
    } catch (error) {
      console.error("Error sending POST requests:", error);
      return false;
    }
  };

  const validateURLs = async (dynamicFields) => {
    if (dynamicFields.length > 0) {
      dispatch(setGlobalLoaderMessage("Checking if the urls are valid..."));
      for (const field of dynamicFields) {
        let fullURL = `${field.url}/wp-json/wc/v3/products/categories`;
        try {
          const response = await fetchWoocommerceCategories({
            urlPart: fullURL,
            per_page: 1,
            page: 1,
            key: field.cunsumer_key,
            secret: field.cunsumer_secret,
          });

          if (response.data.length == 0) {
            // If any URL is not valid, return false immediately
            return false;
          }
        } catch (error) {
          // Error occurred during the request
          console.error(`Error checking URL ${field.url}: ${error.message}`);
          dispatch(setGlobalLoaderMessage("Something went wrong, Please try again!"));
          return false;
        } finally {
          // Loading.remove(500);
        }
      }

      return true;
    } else {
      Notify.failure("You have to atleast one url");

      return false;
    }
  };

  const fetchCategoriesFromWoocommerce = async (dynamicFields) => {
    try {
      let allCategoriesFetched = true;
      let categoriesArray = [];
      for (const { url, cunsumer_key, cunsumer_secret } of dynamicFields) {
        let currentPage = 1;
        let fullURL = `${url}/wp-json/wc/v3/products/categories`;

        // Loop until there are no more categories
        while (true) {
          const response = await fetchWoocommerceCategories({
            urlPart: fullURL,
            per_page: 100,
            page: currentPage,
            key: cunsumer_key,
            secret: cunsumer_secret,
          });

          const categories = await response.data;

          // Check if categories are empty, indicating the last page
          if (categories.length === 0) {
            const hierarchicalCategories = createHierarchy(categoriesArray);
            localStorage.setItem(
              `fetchedWoocommerceCategories=${url}`,
              JSON.stringify(hierarchicalCategories)
            );
            break; // Return true after all data has been fetched
          } else {
            categoriesArray.push(...categories);
          }
          currentPage += 1;
        }
      }

      return allCategoriesFetched;
    } catch (error) {
      console.error("Error fetching products:", error);
      return false;
    } finally {
    }
  };

  function createHierarchy(categories) {
    const categoryMap = {};

    // Build a map using category IDs
    categories.forEach((category) => {
      categoryMap[category.id] = { ...category, children: [] };
    });

    // Populate the children array
    categories.forEach((category) => {
      if (category.parent !== 0) {
        categoryMap[category.parent].children.push(categoryMap[category.id]);
      }
    });

    // Find top-level categories
    const topLevelCategories = categories.filter((category) => category.parent === 0);

    return topLevelCategories.map((category) => categoryMap[category.id]);
  }

  const fetchProductsFromWoocommerce = async (dynamicFields) => {
    try {
      let allProductsFetched = true;
      for (const { url, cunsumer_key, cunsumer_secret } of dynamicFields) {
        let productsArray = [];
        let currentPage = 1;
        let fullURL = `${url}/wp-json/wc/v3/products`;

        // Loop until there are no more categories
        while (true) {
          const response = await fetchWoocommerceProducts({
            urlPart: fullURL,
            per_page: 100,
            page: currentPage,
            requiredFields:
              "id,name,type,description,short_description,sku,images,attributes,related_ids,average_rating,rating_count,regular_price,price,variations",
            key: cunsumer_key,
            secret: cunsumer_secret,
          });

          const products = await response.data;

          // Check if categories are empty, indicating the last page
          if (products.length === 0) {
            localStorage.setItem(
              `fetchedWoocommerceProducts=${url}`,
              JSON.stringify(productsArray)
            );
            break; // Return true after all data has been fetched
          } else {
            productsArray.push(...products);
          }
          currentPage += 1;
        }
      }
      return allProductsFetched;
    } catch (error) {
      console.error("Error fetching products:", error);
      return false;
    } finally {
    }
  };

  const fetchProductVariationsFromWoocommerce = async (products, field, variationCount) => {
    let variationsAttachedProducts = [...products];
    try {
      let allVariablesFetched = true;
      let count = 0;
      for (const product of variationsAttachedProducts) {
        if (product.type != "simple") {
          count++;
          dispatch(
            setGlobalLoaderMessage(`Fetching product variations.... (${count}/${variationCount})`)
          );

          let fullURL = `${field.url}/wp-json/wc/v3/products/${product.id}/variations`;

          // Loop until there are no more categories
          const response = await fetchWoocommerceVaiations({
            urlPart: fullURL,
            per_page: 100,
            page: 1,
            requiredFields:
              "id,name,type,description,short_description,sku,images,attributes,related_ids,average_rating,rating_count,regular_price,price,variations",
            key: field.cunsumer_key,
            secret: field.cunsumer_secret,
          });

          const variations = await response.data;

          if (variations.length > 0) {
            product.productVariations = variations;
          }
        }
      }

      removeLocalStorageItemsWithPrefix("fetchedWoocommerceProducts=");
      localStorage.setItem(
        `fetchedWoocommerceProducts=${field.url}`,
        JSON.stringify(variationsAttachedProducts)
      );

      return allVariablesFetched;
    } catch (error) {
      console.error("Error fetching products:", error);
      return false;
    } finally {
    }
  };

  useEffect(() => {
    if (countryField) {
      triggerStatesByCountryId(countryField);
      // Block.dots("#dynamicURLElement", {
      //   svgColor: "rgba(159, 3, 3, 0.8)",
      // });
      // triggerUrlsByCountry();
      // Block.remove("#dynamicURLElement", 500);
    }

    // async function triggerUrlsByCountry() {
    //   const urlsByCountryId = await triggerUrlsByCountryId(countryField);
    //   if(urlsByCountryId) {
    //     if(urlsByCountryId.data && urlsByCountryId.data.urls && urlsByCountryId.data.urls.length > 0) {
    //       let urls = urlsByCountryId.data.urls;
    //       setSelectedCountryUrls(urls);
    //     } else {
    //       setSelectedCountryUrls([]);
    //     }
    //   }
    // }
  }, [countryField]);

  useEffect(() => {
    open &&
      isInactiveCountriesSuccess &&
      Block.dots("#dynamicStatesElement", {
        svgColor: "rgba(159, 3, 3, 0.8)",
      });

    isInactiveCountriesSuccess && Block.remove("#dynamicStatesElement", 500);
  }, [isInactiveCountriesSuccess]);

  useEffect(() => {
    if (statesByCountryId)  {
      if (statesByCountryId.length > 0) {
        setValue('regionalTaxes', []);
        setValue('isTaxIncluded', true);
        let filterStates = statesByCountryId.map((item) => ({
          id: item.id,
          value: item.tax_percentage,
        }));
        appendState(filterStates);
      }
    }
  }, [statesByCountryId]);

  // useEffect(() => {
  //   setValue('urls', selectedCountryUrls);
  // }, [selectedCountryUrls])

  const addFields = () => {
    append({ url: "", cunsumer_key: "", cunsumer_secret: "", label: "" });
    // let newfield = { url: "", cunsumer_key: "", cunsumer_secret: "" };
    // setDynamicFields([...dynamicFields, newfield]);
  };

  // const addStateFields = () => {
  //   let newfield = { id: "", value: "" };
  //   setDynamicStates([...dynamicStates, newfield]);
  // };

  const removeFields = (index) => {
    remove(index);
    // let data = [...dynamicFields];
    // data.splice(index, 1);
    // setDynamicFields(data);
  };

  // const removeStateFields = (index) => {
  //   let data = [...dynamicStates];
  //   data.splice(index, 1);
  //   setDynamicStates(data);
  // };

  return (
    <>
    <MDButton variant="gradient" color="primary" sx={{ ml: 2 }} onClick={handleClickOpen}>
      Add New
    </MDButton>
    <BootstrapDialog
          // onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          maxWidth="xl"
          sx={{
            "& .MuiPaper-root": {
              width: "900px",
            },
          }}
          open={open}
        >
          <DialogTitle sx={{ m: 0, p: 2, color: "#3f3f3f" }} id="customized-dialog-title">
            Add Region
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <br />
            <form>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                  <ControlledSelect
                    name={"name"}
                    control={control}
                    label="Select Country *"
                    menu={{
                      id: "id",
                      value: "name",
                      title: "Countries",
                      list: inactiveCountries.filter((item) => item.id != 0),
                    }}
                    defaultValue=""
                    sx={{
                      "& .MuiFormLabel-root": {
                        backgroundColor: "#fff",
                      },
                      "& .MuiInputBase-root": {
                        height: "44px",
                      },
                    }}
                  />
                </GridItem>

                {/* <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                  <ControlledTextInput
                    name="currency"
                    control={control}
                    labelText="Currency *"
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem> */}

                <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                  <ControlledTextInput
                    name="deliveryCharges"
                    control={control}
                    labelText="Delivery charges *"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "number",
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                  <ControlledTextInput
                    name="freeDeliveryAbove"
                    control={control}
                    labelText="Free delivery above *"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "number",
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                  <ControlledTextInput
                    name="expressShippingFee"
                    control={control}
                    labelText="Express shipping fee *"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "number",
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} sx={{ mb: 1 }}>
                  <Divider />
                </GridItem>

                <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                  <ControlledTextInput
                    name="stripeSecretKey"
                    control={control}
                    labelText="Stripe Secret Key *"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Instructions data={instructions.stripe_secret} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                  <ControlledTextInput
                    name="stripePublicKey"
                    control={control}
                    labelText="Stripe Public Key *"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Instructions data={instructions.stripe_public} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                  <ControlledTextInput
                    name="stripeWebhookSecret"
                    control={control}
                    labelText="Stripe Webhook Secret *"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Instructions data={instructions.stripe_webhook} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                  <ControlledTextInput
                    name="invoiceNinjaAPI"
                    control={control}
                    labelText="InvoiceNinja API Key *"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Instructions data={instructions.invoice_ninja_key} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={12}>
                  <br />
                </GridItem>

                <GridItem xs={12} sm={12}>
                  <Box
                    px={2}
                    pb={2}
                    p1={1}
                    sx={{ border: "0.0625rem solid #f0f2f5", borderRadius: "0.375rem" }}
                    id="dynamicURLElement"
                  >
                    <Box display="flex" justifyContent="space-between" my={1}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 500,
                          color: "#3f3f3f",
                        }}
                      >
                        URLs
                      </Typography>
                      <MDButton color="info" variant="gradient" iconOnly onClick={addFields}>
                        <AddBox sx={{}} />
                      </MDButton>
                    </Box>
                    <br />
                    {fields.map((input, index) => {
                      return (
                        <GridContainer key={index}>
                          <GridItem xs={12} sm={12} md={11} lg={11} xl={11}>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                                <ControlledTextInput
                                  name={`urls.${index}.url`}
                                  control={control}
                                  labelText="URL *"
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                />
                              </GridItem>

                              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                                <ControlledTextInput
                                  name={`urls.${index}.cunsumer_key`}
                                  control={control}
                                  labelText="Consumer Key *"
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <Instructions data={instructions.consumer_key} />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </GridItem>

                              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                                <ControlledTextInput
                                  name={`urls.${index}.cunsumer_secret`}
                                  control={control}
                                  labelText="Consumer Secret *"
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <Instructions data={instructions.consumer_secret} />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                                <ControlledTextInput
                                  name={`urls.${index}.label`}
                                  control={control}
                                  labelText="Label *"
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                />
                              </GridItem>
                            </GridContainer>
                          </GridItem>

                          <GridItem xs={12} sm={12} md={1} lg={1} xl={1}>
                            <MDButton
                              color="warning"
                              variant="gradient"
                              iconOnly
                              onClick={() => removeFields(index)}
                            >
                              <IndeterminateCheckBoxIcon />
                            </MDButton>
                          </GridItem>
                        </GridContainer>
                      );
                    })}
                  </Box>
                </GridItem>

                <GridItem xs={12} sm={12}>
                  <br />
                </GridItem>

                <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                  <ControlledCheckbox
                    name="isTaxIncluded"
                    control={control}
                    label="Is Tax included?"
                    disabled={countryField == 0 ? true : false}
                  />
                </GridItem>

                {isTaxIncluded ? (
                  <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                    <ControlledTextInput
                      name="taxPercentage"
                      control={control}
                      labelText="Tax Percentage"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "number",
                      }}
                    />
                  </GridItem>
                ) : (
                  <GridItem xs={12} sm={12}>
                    <Box
                      px={2}
                      pb={2}
                      p1={1}
                      sx={{ border: "0.0625rem solid #f0f2f5", borderRadius: "0.375rem" }}
                      id="dynamicStatesElement"
                    >
                      <Box display="flex" justifyContent="space-between" my={1}>
                        <Typography
                          variant="h6"
                          sx={{
                            fontWeight: 500,
                            color: "#3f3f3f",
                          }}
                        >
                          States
                        </Typography>
                        {/* <MDButton color="info" variant="gradient" iconOnly onClick={addStateFields}>
                        <AddBox sx={{}} />
                      </MDButton> */}
                      </Box>
                      <br />
                      {stateFields.map((input, index) => {
                        return (
                          <GridContainer key={index}>
                            <GridItem xs={12} sm={12} md={11} lg={11} xl={11}>
                              <GridContainer>
                                <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                                  <ControlledSelect
                                    name={`regionalTaxes.${index}.id`}
                                    control={control}
                                    label="Select State *"
                                    menu={{
                                      id: "id",
                                      value: "state_name",
                                      title: "State",
                                      list: statesByCountryId,
                                    }}
                                    sx={{
                                      "& .MuiFormLabel-root": {
                                        backgroundColor: "#fff",
                                      },
                                      "& .MuiInputBase-root": {
                                        height: "44px",
                                      },
                                    }}
                                    inputProps={{
                                      readOnly: true,
                                    }}
                                  />
                                </GridItem>

                                <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                                  <ControlledTextInput
                                    name={`regionalTaxes.${index}.value`}
                                    control={control}
                                    labelText="Tax percentage *"
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    inputProps={{
                                      
                                    }}
                                  />
                                </GridItem>
                              </GridContainer>
                            </GridItem>
                          </GridContainer>
                        );
                      })}
                    </Box>
                  </GridItem>
                )}

                <GridItem xs={12} sm={12} md={4} lg={4} xl={4}></GridItem>

                <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                  <br />
                  <FormControl variant="outlined">
                    <InputLabel
                      htmlFor="terms-condition"
                      sx={{
                        transform: "translate(12px, -5px) scale(.95)",
                        backgroundColor: "#fff",
                        padding: "0 5px 0 0",
                      }}
                    >
                      Terms And Conditions
                    </InputLabel>
                    <MDBox>
                      <Editor
                        id="terms-condition"
                        editorState={editorState}
                        onEditorStateChange={setEditorState}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                      />
                    </MDBox>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </form>
          </DialogContent>
          <DialogActions>
            <MDButton
              type="submit"
              color="primary"
              variant="gradient"
              id="submit"
              onClick={handleSubmit(onSubmit)}
            >
              Add Country
            </MDButton>
            <MDButton color="secondary" variant="gradient" autoFocus onClick={handleClose}>
              Cancel
            </MDButton>
          </DialogActions>
        </BootstrapDialog>
    </>
  );
}

export default AddCountry;
