import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import PropTypes from "prop-types";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { styled } from "@mui/material/styles";

import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";

import Tooltip from "@mui/material/Tooltip";

import MDButton from "components/MDButton";
import ControlledTextInput from "components/FormInputs/ControlledTextInput";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { globalCountry } from "store/entities/countries";

import { useChangeOrderPaymentStatusMutation, useRefundOrderMutation } from "services/orders";

import { refundSchema } from "./schemas/_model";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const RefundDialog = ({ order, remainingAmount }) => {
  const selectedCountry = useSelector(globalCountry);

  const [openDialog, setOpenDialog] = useState(false);

  const initialFormState = {
    amount: 0,
    reason: "",
  };

  const { control, watch, handleSubmit, setValue } = useForm({
    resolver: yupResolver(refundSchema(remainingAmount)),
    defaultValues: initialFormState,
    mode: "onChange",
  });

  useEffect(() => {
    setValue("amount", remainingAmount);
  }, [remainingAmount]);

  const [changePaymentStatus, { isLoading: isOrderPaymentStatusesLoading }] =
    useChangeOrderPaymentStatusMutation();

  const [refundOrder, { isLoading: isRefundLoading }] = useRefundOrderMutation();

  const refundSubmit = (data) => {
    data.countryId = order.reseller.companies.country_id;
    data.orderId = order.id;
    data.paymentMethod = order.payment_method;
    data.paymentIntentId = order.payment_intent_id;
    data.resellerId = order.reseller_id;

    if (data.paymentMethod == "CARD" && data.paymentIntentId == "") {
      Notify.failure("Payment Intent Id is not found!");
    } else {
      try {
        Loading.dots({
          svgColor: "rgba(159, 3, 3, 0.8)",
        });
        refundOrder(data).then((response) => {
          if (response.data?.status == "success") {
            if (response.data.response.is_completed) {
              changePaymentStatus({
                order_id: order.id,
                status: "REFUNDED",
                invoiceNinjaAPI: selectedCountry?.invoiceNinjaAPI,
              }).then((responseStatus) => {
                if (responseStatus.data?.status == "success") {
                  Notify.success("Succefully Refunded!");
                }
              });
            }
          }
        });
      } catch (e) {
      } finally {
        setOpenDialog(false);
        Loading.remove(500);
      }
    }
  };

  return (
    <>
      <Tooltip title="Refund" placement="top">
        <MDButton
          size="small"
          color="secondary"
          variant="gradient"
          sx={{
            ml: 1,
            color: "#fff",
            fontSize: "10px!important",
            fontWeight: "400",
          }}
          onClick={() => {
            setOpenDialog(true);
          }}
        >
          Refund
        </MDButton>
      </Tooltip>
      <BootstrapDialog
        onClose={() => setOpenDialog(false)}
        aria-labelledby="customized-dialog-title"
        maxWidth="lg"
        sx={{
          "& .MuiPaper-root": {
            width: "550px",
          },
        }}
        open={openDialog}
      >
        <DialogTitle sx={{ m: 0, p: 2, color: "#4b4b4f" }} id="customized-dialog-title">
          Refund
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpenDialog(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography
            variant="p"
            sx={{
              fontWeight: 400,
              mt: 1,
              color: "#3f3f3f",
            }}
          >
            Please enter an amount to refund!
          </Typography>
          <form>
            <ControlledTextInput
              name="amount"
              control={control}
              labelText="Amount *"
              formControlProps={{
                fullWidth: true,
              }}
              sx={{ mt: 2 }}
              inputProps={{
                type: "number",
              }}
            />
            <ControlledTextInput
              name="reason"
              control={control}
              labelText="Reason *"
              formControlProps={{
                fullWidth: true,
              }}
              multiline
              sx={{ mt: 2 }}
              // inputProps={{
              //     type: "text",
              // }}
            />
          </form>
          <br />
        </DialogContent>
        <DialogActions>
          <MDButton
            type="submit"
            color="primary"
            variant="gradient"
            id="submit"
            onClick={handleSubmit(refundSubmit)}
          >
            Submit
          </MDButton>
          <MDButton
            color="secondary"
            variant="gradient"
            autoFocus
            onClick={() => setOpenDialog(false)}
          >
            Cancel
          </MDButton>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

RefundDialog.propTypes = {
  order: PropTypes.object,
  remainingAmount: PropTypes.number,
};

export default RefundDialog;
