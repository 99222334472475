import { apis } from "./base";

const categoriesService = apis.injectEndpoints({
  endpoints: (builder) => ({
    getAllResellerProductCategories: builder.query({
      query: (countryId) => {
        return `/admin/categories/all/${countryId}`;
      },
      transformResponse: (response) => response.response,
      providesTags: ["Categories"],
    }),

    getWoocommerceSiteCategories: builder.mutation({
      query: (data) => ({
        url: `/admin/woocommerce-fetch`,
        method: "POST",
        body: data,
      }),
      transformResponse: (response) => response.response,
      invalidatesTags: [],
    }),

    // getProductById: builder.query({
    //   query: (id) => {
    //     return `/admin/products/id/${id}`;
    //   },
    //   transformResponse: (response) => response.response,
    //   providesTags: ["Products"],
    // }),

    postCategoryName: builder.mutation({
      query: (data) => ({
        url: `/admin/categories/new`,
        method: "POST",
        body: data,
      }),
      // transformResponse: (response) => response.response
      // invalidatesTags: ["Categories"],
    }),

    putCategoryName: builder.mutation({
      query: (data) => ({
        url: `/admin/categories/edit/${data.id}`,
        method: "PUT",
        body: data,
      }),
      // transformResponse: (response) => response.response
      // invalidatesTags: ["Categories"],
    }),

    // deleteProduct: builder.mutation({
    //   query: (id) => ({
    //     url: `/admin/products/delete/${id}`,
    //     method: "DELETE",
    //   }),
    //   transformResponse: (response) => response.response,
    //   invalidatesTags: ["Products"],
    // }),

    // getAllCountries: builder.query({
    //     query: () => ({
    //       url: "/countries/all",
    //     }),
    //     transformResponse: (response) => response.response,
    //     // providesTags: ["Countries"],
    // }),

    // getOrderProductsByOrderId: builder.query({
    //   query: (order_id) => {
    //     return `/admin/orders/id/${order_id}`;
    // },
    //   transformResponse: (response) => response.response,
    //   providesTags: ["OrderProducts"],
    // }),
  }),
});

export const {
  useGetAllResellerProductCategoriesQuery,
  useLazyGetAllResellerProductCategoriesQuery,
  useGetWoocommerceSiteCategoriesMutation,
  usePostCategoryNameMutation,
  usePutCategoryNameMutation,
  // useGetProductByIdQuery,
  // useLazyGetProductByIdQuery,
  // useGetAllResellerProductsByCountryIdQuery,
  // useLazyGetAllResellerProductsByCountryIdQuery,
  // usePostProductMutation,
  // usePutProductMutation,
  // useDeleteProductMutation
} = categoriesService;
