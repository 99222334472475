/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import axios from "axios";

import { Importer, ImporterField } from "react-csv-importer";

import { FilePond, registerPlugin } from "react-filepond";

import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { styled } from "@mui/material/styles";

// @mui material components
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import MDButton from "components/MDButton";

import { AddBox } from "@mui/icons-material";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";

import TextField from "@mui/material/TextField";

import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import { Confirm } from "notiflix/build/notiflix-confirm-aio";

import MDTypography from "components/MDTypography";

import Typography from "@mui/material/Typography";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import ControlledTextInput from "components/FormInputs/ControlledTextInput";
import ControlledCheckbox from "components/FormInputs/ControlledCheckbox";
import CustomInputs from "components/CustomInputs";
import ControlledSelect from "components/FormInputs/ControlledSelect";

//components
import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import Tooltip from "@mui/material/Tooltip";

import Box from "@mui/material/Box";

import DialogActions from "@mui/material/DialogActions";

import AddDigitalKeys from "../components/AddDigitalKeys";

import { addVariationSchema } from "../schemas/_model";

// theme CSS for React CSV Importer
import "react-csv-importer/dist/index.css";

// queries
import {
  useGetAllResellerProductsByCountryIdQuery,
  usePostProductMutation,
} from "services/products";

import { replaceSingleQuoteToSQLFriendly } from "utils/helperFunctions";

// import { useGetAllProductsFromStoreUrlQuery } from "services/store";

import DeleteIcon from "@mui/icons-material/Delete";

import { globalCountry, selectedCountryURL } from "store/entities/countries";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const EditVariationDialog = ({
  handleEditProductVariationSubmit,
  initData,
  parentAttributes,
  children,
  deleteVariation,
}) => {
  const initialFormState = {
    isDigitalGood: false,
    price: 0,
    retailPrice: 0,
    name: "",
    inStock: true,
    // averageRating: 0,
    // ratingCount: 0,
    shortDescription: "",
    sku: "",
    licenseDuration: 0,
    digitalKeys: [],
  };

  const selectedCountry = useSelector(globalCountry);

  const [imageLoading, setImageLoading] = useState(true);

  const [selectedIsDigitalKeyChanged, setSelectedIsDigitalKeyChanged] = useState(false);

  const [attributeFields, setAttributeFields] = useState([]);

  const [availabilityFields, setAvailabilityFields] = useState([]);

  const [attributeDialog, setAttributeDialog] = useState(false);

  const [openAddVariationDialog, setOpenEditVariationDialog] = useState(false);

  const [file, setFile] = useState([]);

  const [storedVariationImage, setStoredVariationImage] = useState("");

  // const [uploadFile, { isLoading: isFileUploading }] = useUploadFileMutation();

  const dispatch = useDispatch();

  const { control, watch, handleSubmit, setValue, reset, formState } = useForm({
    resolver: yupResolver(addVariationSchema(selectedIsDigitalKeyChanged)),
    defaultValues: initialFormState,
  });

  const { fields, append, remove } = useFieldArray({ name: "attributes", control });

  const handleEditVariationDialogOpen = () => {
    setOpenEditVariationDialog(true);
    if (initData) {
      let localStoredVariationImage = localStorage.getItem(`variationImage=${initData.id}`);
      if (localStoredVariationImage) {
        let url = "" + localStoredVariationImage + "";
        setStoredVariationImage(url);
      }
    }
  };

  const handleEditVariationDialogClose = () => {
    setOpenEditVariationDialog(false);
  };

  const isDigitalGoodField = watch("isDigitalGood");

  const onSubmit = (data) => {
    if (fields.length == 0) {
      Notify.failure("You must add atleast one attribute!");
      return;
    }

    data.description = "<p>&nbsp; </p>";
    data.shortDescription = "<p>&nbsp; </p>";
    data.id = initData.id;

    if (storedVariationImage) {
      if (file && file.length != 0) {
        data.variationImage = file;

        let reader = new FileReader();
        reader.onloadend = function (e) {
          localStorage.setItem(`variationImage=${data.id}`, reader.result); //stores the image to localStorage
        };
        reader.readAsDataURL(file);
      } else {
        data.variationImage = initData.variationImage;
      }
    } else {
      delete initData.variationImage;
    }

    data.woocommerceId = 0;
    // data.attributes = [];
    data.ratingCount = 0;
    data.averageRating = 0;
    data.images = [];

    if (isDigitalGoodField) {
      if (data.digitalKeys.length == 0 && availabilityFields.length == 0) {
        data.digitalKeys = [];
        Notify.failure("You must add atleast one digital key.");
        return;
      }
    } else {
      data.digitalKeys = [];
    }

    handleEditProductVariationSubmit(data, availabilityFields);

    setAvailabilityFields([]);

    reset();
    // setAttributeFields([{ attribute: "", option: "" }]);
    handleEditVariationDialogClose();
  };

  const handleUpdateFiles = (files) => {
    if (files.length > 0) {
      const photo = files[0].source;
      setFile(photo);
    } else {
      setFile([]);
    }
  };

  const addAttributeFields = (attributeName) => {
    append({ attribute: attributeName, option: "" });
    let ifAttributeExists = fields.filter((item) => item.attribute == attributeName);
    if (!(ifAttributeExists && ifAttributeExists.length > 0))
      // let newfield = { attribute: attributeName, option: "" };
      setAttributeFields([...attributeFields, { attribute: attributeName, option: "" }]);
  };

  const removeAttributeFields = (index) => {
    remove(index);
    // let data = [...attributeFields];
    // data.splice(index, 1);
    // setAttributeFields(data);
  };

  const deleteExistingImage = () => {
    setStoredVariationImage("");
    delete initData.variationImag;
    localStorage.removeItem(`variationImage=${initData.id}`);
  };

  useEffect(() => {
    setSelectedIsDigitalKeyChanged(isDigitalGoodField);
  }, [isDigitalGoodField]);

  useEffect(() => {
    // setDynamicFields(initData.digitalKeys);
    if (initData) {
      if (initData.pendingKeys && initData.pendingKeys.length > 0) {
        let pendingKeys = [];
        for (let key of initData.pendingKeys) {
          pendingKeys.push(key);
        }
        if (pendingKeys && pendingKeys.length > 0) {
          setAvailabilityFields(pendingKeys);
        }
      }

      let modifiedInitialData = initData;

      if (initData.digitalKeys && initData.digitalKeys.length > 0) {
        let realKeys = [];
        for (let key of initData.digitalKeys) {
          realKeys.push({ key: key });
        }
      }

      // if(realKeys.length > 0) {
      //   modifiedInitialData.digitalKeys = realKeys;
      // }
      // setAttributeFields(initData.attributes);

      // delete initData.digitalKeys;

      let localStoredVariationImage = localStorage.getItem(`variationImage=${initData.id}`);
      if (localStoredVariationImage) {
        let url = "" + localStoredVariationImage + "";
        setStoredVariationImage(url);
      }

      reset(modifiedInitialData);
    }
  }, [initData]);

  return (
    <>
      <Tooltip title="Edit variation" placement="top" sx={{ mr: 1 }}>
        <MDButton color="info" variant="gradient" onClick={handleEditVariationDialogOpen}>
          {children}
        </MDButton>
      </Tooltip>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        maxWidth="xl"
        sx={{
          "& .MuiDialog-paper": {
            width: "1000px",
          },
        }}
        open={openAddVariationDialog}
      >
        <DialogTitle sx={{ m: 0, p: 2, color: "#3f3f3f" }} id="customized-dialog-title">
          Edit variation
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleEditVariationDialogClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <MDBox display="flex" justifyContent="end">
            <Tooltip title="Delete this variation" sx={{ ml: 1, mb: 1, mt: -1 }} placement="top">
              <IconButton
                className="deleteButton"
                color="error"
                onClick={() =>
                  Confirm.show(
                    `Please Confirm`,
                    "Are you sure you want to delete this variation?",
                    "Yes",
                    "No",
                    () => {
                      deleteVariation(initData.id);
                    },
                    () => {},
                    {}
                  )
                }
                size="large"
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </MDBox>
          <form>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                <ControlledTextInput
                  name="name"
                  control={control}
                  labelText="Name *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12}>
                <Box
                  px={2}
                  pb={2}
                  p1={1}
                  sx={{ border: "0.0625rem solid #f0f2f5", borderRadius: "0.375rem", mb: 2 }}
                >
                  <Box display="flex" justifyContent="space-between" my={1}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 500,
                        color: "#3f3f3f",
                      }}
                    >
                      Attributes
                    </Typography>
                    <MDButton
                      color="info"
                      variant="gradient"
                      iconOnly
                      onClick={() => setAttributeDialog(true)}
                    >
                      <AddBox sx={{}} />
                    </MDButton>
                  </Box>
                  <br />
                  {fields.map((input, index) => {
                    return (
                      <GridContainer key={index}>
                        <GridItem xs={12} sm={12} md={11} lg={11} xl={11}>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                              <ControlledTextInput
                                name={`attributes.${index}.attribute`}
                                control={control}
                                labelText="Attribute *"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                              {/* <CustomInputs
                                name="attribute"
                                id="attribute"
                                label="Attribute name *"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                value={input.attribute}
                                // inputProps={{
                                //   readOnly: true
                                // }}
                                onChange={(e) => handleAttribeFields(index, e)}
                              /> */}
                            </GridItem>

                            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                              <ControlledTextInput
                                name={`attributes.${index}.option`}
                                control={control}
                                labelText="Option *"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                              {/* <CustomInputs
                                name="option"
                                id="option"
                                label="Option *"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                value={input.option}
                                onChange={(e) => handleAttribeFields(index, e)}
                              /> */}
                            </GridItem>
                          </GridContainer>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={1} lg={1} xl={1}>
                          <MDButton
                            color="warning"
                            variant="gradient"
                            iconOnly
                            onClick={() => removeAttributeFields(index)}
                            // disabled={fields && fields.length > 1 ? false : true}
                          >
                            <IndeterminateCheckBoxIcon />
                          </MDButton>
                        </GridItem>
                      </GridContainer>
                    );
                  })}
                </Box>
              </GridItem>
              <BootstrapDialog
                onClose={() => setAttributeDialog(false)}
                aria-labelledby="customized-dialog-title"
                maxWidth="lg"
                sx={{
                  "& .MuiPaper-root": {
                    width: "450px",
                  },
                }}
                open={attributeDialog}
              >
                <DialogTitle sx={{ m: 0, p: 2, color: "#4b4b4f" }} id="customized-dialog-title">
                  Select Attribute
                </DialogTitle>
                <IconButton
                  aria-label="close"
                  onClick={() => setAttributeDialog(false)}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                  {parentAttributes.map((input, index) => {
                    return (
                      <MDButton
                        key={index}
                        sx={{ mr: 1 }}
                        color="info"
                        variant="gradient"
                        onClick={() => {
                          addAttributeFields(input);
                          setAttributeDialog(false);
                        }}
                      >
                        {input}
                      </MDButton>
                    );
                  })}
                  <br />
                </DialogContent>
                {/* <DialogActions>
                      <MDButton color="secondary" variant="gradient" autoFocus onClick={() => setAttributeDialog(false)}>
                          Cancel
                      </MDButton>
                  </DialogActions> */}
              </BootstrapDialog>
              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextInput
                  name="price"
                  control={control}
                  labelText="Enter Reseller Price *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "number",
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextInput
                  name="retailPrice"
                  control={control}
                  labelText="Enter Retailer Price *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "number",
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12}>
                <>
                  {storedVariationImage != "" ? (
                    <MDBox sx={{ position: "relative", zIndex: 0, display: "inline-block" }}>
                      <MDBox
                        mb={2}
                        component="img"
                        src={storedVariationImage}
                        sx={{ width: "100%", position: "relative", mt: 3, maxWidth: "400px" }}
                      />
                      <Tooltip title="Delete existing image" placement="top">
                        <IconButton
                          sx={{ position: "absolute", zIndex: 1, right: "-23px", top: "-2px" }}
                          aria-label="view"
                          size="small"
                          onClick={() => deleteExistingImage()}
                        >
                          <HighlightOffRoundedIcon color="error" fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                  ) : (
                    <Box
                      component={FilePond}
                      sx={{
                        fontFamily: '"Poppins", "Roboto", "Helvetica", "Arial", sans-serif',
                        "& .filepond--file-action-button": {
                          cursor: "pointer",
                        },
                        "& .filepond--drop-label.filepond--drop-label label": {
                          fontSize: "13px",
                          letterSpacing: "0.5px",
                          fontWeight: "600",
                        },
                        mt: 2,
                      }}
                      instantUpload={false}
                      allowFileSizeValidation={true}
                      name="file"
                      allowFileTypeValidation={true}
                      acceptedFileTypes={[
                        "image/jpeg",
                        "image/jpg",
                        "image/png",
                        "application/msword", // for .doc
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // for .docx
                        "application/pdf",
                      ]}
                      allowMultiple={false}
                      allowFilePoster={false}
                      labelIdle={
                        'Drag & Drop product image or <span class="filepond--label-action">Browse</span>'
                      }
                      onupdatefiles={handleUpdateFiles}
                      allowRevert={false}
                    />
                  )}
                </>
              </GridItem>

              {/* <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextInput
                  name="averageRating"
                  control={control}
                  labelText="Average rating *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "number",
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextInput
                  name="ratingCount"
                  control={control}
                  labelText="Rating count *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "number",
                  }}
                />
              </GridItem> */}

              {/* <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledCheckbox
                  name="isFeaturedProduct"
                  control={control}
                  label="Is Feature Product?"
                />
              </GridItem> */}

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledCheckbox name="inStock" control={control} label="Is in stock?" />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledCheckbox
                  name="isDigitalGood"
                  control={control}
                  label="Is Digital Good?"
                />
              </GridItem>

              {isDigitalGoodField && (
                <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                  <ControlledTextInput
                    name="licenseDuration"
                    control={control}
                    labelText="License Duration (no of months) *"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "number",
                    }}
                  />
                </GridItem>
              )}

              {isDigitalGoodField ? (
                <AddDigitalKeys
                  control={control}
                  availabilityFields={availabilityFields}
                  setAvailabilityFields={setAvailabilityFields}
                />
              ) : (
                <></>
              )}

              <GridItem xs={12} sm={12}>
                <br />
              </GridItem>
            </GridContainer>
          </form>
        </DialogContent>
        <DialogActions>
          * Required fields
          <Box sx={{ marginLeft: "auto" }}>
            <MDButton
              color="primary"
              variant="gradient"
              id="submit"
              onClick={handleSubmit(onSubmit)}
            >
              Update variation
            </MDButton>
            <MDButton
              color="secondary"
              variant="gradient"
              onClick={() => handleEditVariationDialogClose()}
              sx={{ ml: 1 }}
            >
              Cancel
            </MDButton>
          </Box>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

export default EditVariationDialog;
